import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vModelText as _vModelText, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "custom-category-child-component" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "inline-flex100 align-items-center" }
const _hoisted_4 = { class: "main-title-width" }
const _hoisted_5 = { class: "main-heading primary-heading" }
const _hoisted_6 = { class: "inline-flex" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "padding-top-20" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = {
  key: 0,
  class: "alert alert-success margin-top-10"
}
const _hoisted_11 = {
  key: 1,
  class: "shared-with-other-event-position margin-right-7 parent-information-icon-div-shared-with-other-event"
}
const _hoisted_12 = ["disabled"]
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { class: "top-margin-in-fields" }
const _hoisted_15 = { class: "" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = ["value", "selected"]
const _hoisted_18 = { class: "inline-flex100 top-margin-in-fields align-items-end" }
const _hoisted_19 = {
  id: "logo-div",
  class: "display-flex"
}
const _hoisted_20 = ["src", "title"]
const _hoisted_21 = {
  id: "content-div",
  class: "width100 margin-left-10"
}
const _hoisted_22 = {
  id: "textarea-div",
  class: "margin-top-10 width100"
}
const _hoisted_23 = {
  for: "txtAreaCustomCategoryTitle",
  class: "textarea-label font-size-14"
}
const _hoisted_24 = { class: "color-red" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "margin-top-20" }
const _hoisted_27 = { class: "margin-top-20" }
const _hoisted_28 = {
  key: 0,
  id: "divCustomCategoryDescription",
  class: "margin-top-25"
}
const _hoisted_29 = {
  for: "Description",
  class: "margin-top-0"
}
const _hoisted_30 = {
  key: 1,
  class: "margin-top-20"
}
const _hoisted_31 = { class: "radio-text" }
const _hoisted_32 = {
  key: 0,
  class: "color-red"
}
const _hoisted_33 = { key: 2 }
const _hoisted_34 = { class: "margin-top-20" }
const _hoisted_35 = { for: "Folder" }
const _hoisted_36 = { value: 0 }
const _hoisted_37 = ["value"]
const _hoisted_38 = { key: 3 }
const _hoisted_39 = { class: "margin-top-25" }
const _hoisted_40 = { key: 4 }
const _hoisted_41 = { class: "section-heading" }
const _hoisted_42 = {
  key: 5,
  class: "ListOfParticipantGroups"
}
const _hoisted_43 = { key: 6 }
const _hoisted_44 = {
  key: 7,
  class: "margin-35-0"
}
const _hoisted_45 = {
  key: 8,
  class: "margin-top-25 margin-bottom-35"
}
const _hoisted_46 = {
  id: "customCategoryLinkedProgramDiv",
  class: "margin-top--10"
}
const _hoisted_47 = {
  key: 9,
  id: "customCategoryMultimediaListView"
}
const _hoisted_48 = { class: "margin-top--10" }
const _hoisted_49 = { key: 2 }
const _hoisted_50 = { key: 3 }
const _hoisted_51 = { key: 4 }
const _hoisted_52 = { key: 5 }
const _hoisted_53 = {
  key: 6,
  class: "edit-panel"
}
const _hoisted_54 = {
  key: 7,
  class: "right-container-margin"
}
const _hoisted_55 = {
  key: 8,
  class: "right-container-margin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_Editor = _resolveComponent("Editor")!
  const _component_AddUrl = _resolveComponent("AddUrl")!
  const _component_InlineFileUploader = _resolveComponent("InlineFileUploader")!
  const _component_MapPicker = _resolveComponent("MapPicker")!
  const _component_VideoOnDemandComponent = _resolveComponent("VideoOnDemandComponent")!
  const _component_ListOfParticipantGroups = _resolveComponent("ListOfParticipantGroups")!
  const _component_EventWebPageVisibiltity = _resolveComponent("EventWebPageVisibiltity")!
  const _component_CustomCategoryLinkedParticipantListView = _resolveComponent("CustomCategoryLinkedParticipantListView")!
  const _component_CustomCategoryLinkedProgramListView = _resolveComponent("CustomCategoryLinkedProgramListView")!
  const _component_CustomCategoryMultimediaListView = _resolveComponent("CustomCategoryMultimediaListView")!
  const _component_ImageProcess = _resolveComponent("ImageProcess")!
  const _component_AddProgramWithEntity = _resolveComponent("AddProgramWithEntity")!
  const _component_CustomCategoryShareWithOtherEvents = _resolveComponent("CustomCategoryShareWithOtherEvents")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_CommonSearchComponent = _resolveComponent("CommonSearchComponent")!
  const _component_MuxVideo = _resolveComponent("MuxVideo")!
  const _component_PreRecordedMeetingHighcharts = _resolveComponent("PreRecordedMeetingHighcharts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('Delete'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button", "show-cancel-button", "cancel-button-text", "confirm-button-text"]))
      : _createCommentVNode("", true),
    (_ctx.ShowCustomCategoryEditDetailView())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  id: "btnCancelCustomCategory",
                  class: "btn btn-secondary btn-size-default",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Cancel(_ctx.v$)))
                }, _toDisplayString(_ctx.$t("CancelButton")), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.localSelectedBaseCustomCategoryRenderer.CustomCategoryHeading), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  id: "btnSaveCustomCategory",
                  class: "btn btn-primary btn-size-default",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Submit(_ctx.v$)))
                }, _toDisplayString(_ctx.$t("SaveButton")), 1),
                (!_ctx.localCustomCategoryItem.CustomCategoryId==0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "btn btn-delete",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ClickDeleteCustomCategory()))
                    }, [
                      _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_7)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                (_ctx.visibleValidationSummary)
                  ? (_openBlock(), _createBlock(_component_ValidationSummary, {
                      key: 0,
                      "error-fields": _ctx.validationErrorFields,
                      onCloseClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.CloseValidationSummary($event)))
                    }, null, 8, ["error-fields"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.messages.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
                      return (_openBlock(), _createElementBlock("span", { key: message }, _toDisplayString(message), 1))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.localCustomCategoryItem.CustomCategoryId > 0 && _ctx.showSharedEventsLink)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("a", {
                      class: "hyperLink inline-flex",
                      disabled: _ctx.isDisabledShareWithOtherEvent(),
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ShowSharedEvents(true))),
                      onMouseover: _cache[6] || (_cache[6] = ($event: any) => (_ctx.getInformationString(true))),
                      onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.getInformationString(false)))
                    }, [
                      _createElementVNode("span", {
                        class: "add-link",
                        disabled: _ctx.isDisabledShareWithOtherEvent(),
                        style: _normalizeStyle([!_ctx.isDisabledShareWithOtherEvent() ? {'cursor': 'pointer'}: ''])
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("Information.ShareWithOtherevents")), 1)
                      ], 12, _hoisted_13),
                      _withDirectives(_createElementVNode("div", { class: "information-icon-div-shared-with-other-event" }, _toDisplayString(_ctx.$t("Information.ShareWithOtherEventDisableMessage")), 513), [
                        [_vShow, _ctx.showInformation]
                      ])
                    ], 40, _hoisted_12)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t("CustomCategory.InfomationType")), 1),
                  _createElementVNode("select", {
                    id: "selCustomCategoryType",
                    class: "form-control question-type-width selectBox width45",
                    disabled: _ctx.isDisabled,
                    onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.OnCustomcategoryTypeChange($event)))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.CustomCategoryTypes.arrCustomCategoryOptions, (customCategory) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: customCategory.Id,
                        value: customCategory.Id,
                        selected: customCategory.Name == _ctx.selCustomCategoryType
                      }, _toDisplayString(_ctx.$t(customCategory.DisplayName)), 9, _hoisted_17))
                    }), 128))
                  ], 40, _hoisted_16)
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", {
                    id: "divCustomCategoryImage",
                    class: _normalizeClass(["img-select margin-right-10", {'full-image-icon-div': _ctx.localCustomCategoryItem.IsFullImage}]),
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.clickOnImage(true)))
                  }, [
                    _createElementVNode("img", {
                      id: "imgCustomCategoryImage",
                      class: _normalizeClass([_ctx.localCustomCategoryItem.IsFullImage ? 'full-image-icon': 'image-icon', {'round-image': !_ctx.localCustomCategoryItem.IsFullImage}]),
                      src: _ctx.GetImageUrl(),
                      title: _ctx.$t('AddEditImage')
                    }, null, 10, _hoisted_20)
                  ], 2)
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("label", _hoisted_23, [
                      _createTextVNode(_toDisplayString(_ctx.localSelectedBaseCustomCategoryRenderer.TitleText), 1),
                      _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("Asteric")), 1)
                    ]),
                    _withDirectives(_createElementVNode("textarea", {
                      id: "textAreaCustomCategoryTitle",
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.v$.localCustomCategoryItem.Title.$model) = $event)),
                      class: _normalizeClass(["form-control textarea-title textarea-size", _ctx.status(_ctx.v$.localCustomCategoryItem.Title)]),
                      maxlength: "120"
                    }, null, 2), [
                      [
                        _vModelText,
                        _ctx.v$.localCustomCategoryItem.Title.$model,
                        void 0,
                        { trim: true }
                      ]
                    ])
                  ])
                ])
              ]),
              (_ctx.IsInformatiomTypeSpeaker())
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t("Title")), 1),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localCustomCategoryItem.JobTitle) = $event)),
                        maxlength: "80",
                        type: "text",
                        class: "form-control"
                      }, null, 512), [
                        [
                          _vModelText,
                          _ctx.localCustomCategoryItem.JobTitle,
                          void 0,
                          { trim: true }
                        ]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t("Company")), 1),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localCustomCategoryItem.Company) = $event)),
                        maxlength: "80",
                        type: "text",
                        class: "form-control"
                      }, null, 512), [
                        [
                          _vModelText,
                          _ctx.localCustomCategoryItem.Company,
                          void 0,
                          { trim: true }
                        ]
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          (_ctx.ShowDescription())
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.localSelectedBaseCustomCategoryRenderer.DescriptionText), 1),
                _createVNode(_component_Editor, {
                  id: "editorCustomCategoryDescription",
                  description: _ctx.localCustomCategoryItem.Description,
                  onUpdatedDescription: _ctx.SetDescriptionValue
                }, null, 8, ["description", "onUpdatedDescription"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.ShowAddUrl())
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                _createElementVNode("label", null, [
                  _createElementVNode("span", _hoisted_31, [
                    _createTextVNode(_toDisplayString(_ctx.localSelectedBaseCustomCategoryRenderer.UrlText), 1),
                    (!_ctx.CheckIfInformationTypeIsSponsor())
                      ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.$t("Asteric")), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", {
                  id: "divURLCustomCategory",
                  class: _normalizeClass({ 'editPane error': (_ctx.localCustomCategoryItem.ContentUrl === '' && _ctx.visibleValidationSummary)})
                }, [
                  _createVNode(_component_AddUrl, {
                    "item-value": _ctx.GetUrl(),
                    "is-required": _ctx.isRequired,
                    onIsValidUrl: _cache[13] || (_cache[13] = ($event: any) => (_ctx.IsValidUrl($event))),
                    onUrl: _cache[14] || (_cache[14] = ($event: any) => (_ctx.SetUrl($event)))
                  }, null, 8, ["item-value", "is-required"])
                ], 2)
              ]))
            : _createCommentVNode("", true),
          (_ctx.ShowFileUpload())
            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                _createVNode(_component_InlineFileUploader, {
                  "allowed-extensions": _ctx.allowedFileExtensions,
                  "allow-multiple-files": _ctx.allowMultipleFilesUpload,
                  "max-size": _ctx.pdfMaxSize,
                  "heading-title": _ctx.GetTitle(),
                  "open-file-text": _ctx.$t('CustomCategory.FileButtonText'),
                  "existing-file-url": _ctx.GetUrl(),
                  "module-name": _ctx.CustomCategoryModule,
                  "sub-module-name": _ctx.presentation,
                  onIsValidFile: _cache[15] || (_cache[15] = ($event: any) => (_ctx.IsValidFile($event))),
                  onFileurl: _cache[16] || (_cache[16] = ($event: any) => (_ctx.SetFileUrl($event))),
                  onErrorMessage: _cache[17] || (_cache[17] = ($event: any) => (_ctx.SetErrorMessage($event)))
                }, null, 8, ["allowed-extensions", "allow-multiple-files", "max-size", "heading-title", "open-file-text", "existing-file-url", "module-name", "sub-module-name"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t("Information.Folder")), 1),
            _withDirectives(_createElementVNode("select", {
              id: "selFolders",
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.localCustomCategoryItem.CategoryFolder.Id) = $event)),
              class: "form-control selectBox"
            }, [
              _createElementVNode("option", _hoisted_36, _toDisplayString(_ctx.$t("Information.NoFolder")), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableCustomCategoryFolders, (folder, index) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: index,
                  value: folder.Id
                }, _toDisplayString(folder.Name), 9, _hoisted_37))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.localCustomCategoryItem.CategoryFolder.Id]
            ])
          ]),
          (_ctx.ShowAddress())
            ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _createVNode(_component_MapPicker, {
                    "selected-item": _ctx.localCustomCategoryItem,
                    "show-heading": true
                  }, null, 8, ["selected-item"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.ShowVodSection())
            ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                _cache[31] || (_cache[31] = _createElementVNode("hr", { class: "margin-40-0-35" }, null, -1)),
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, [
                    _createElementVNode("h4", _hoisted_41, _toDisplayString(_ctx.$t("IncludeVideo")), 1)
                  ]),
                  _createVNode(_component_VideoOnDemandComponent, {
                    "selected-vod-base-classs": _ctx.selectedVodRenderer,
                    "vonage-threshold-limit-reached": _ctx.vonageThresholdLimitReached,
                    onThreshHold: _cache[19] || (_cache[19] = ($event: any) => (_ctx.showThreshHold($event)))
                  }, null, 8, ["selected-vod-base-classs", "vonage-threshold-limit-reached"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.EnableLinkParticipantToInformation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                _cache[32] || (_cache[32] = _createElementVNode("hr", { class: "margin-40-0-30" }, null, -1)),
                _createVNode(_component_ListOfParticipantGroups, {
                  "participant-groups": _ctx.participantGroups,
                  "sub-heading": _ctx.subHeadingString,
                  onSendListOfParticipantGroupsIneditpane: _ctx.SetListParticipantGroups,
                  onSendListOfParticipantGroupsInLocalItem: _ctx.setListInLocalItem
                }, null, 8, ["participant-groups", "sub-heading", "onSendListOfParticipantGroupsIneditpane", "onSendListOfParticipantGroupsInLocalItem"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_EventWebPageVisibiltity, {
            "is-checkbox-visible": _ctx.IsEventWebpagePFEnabledAndInformatiomTypeSponsorOrSpeaker(),
            "module-name": _ctx.selCustomCategoryType.toLowerCase(),
            "is-checkbox-checked": _ctx.localCustomCategoryItem.HideFromEventWebpage,
            onSetCheckboxValue: _cache[20] || (_cache[20] = ($event: any) => (_ctx.SetEventWebpageCheckboxValue($event)))
          }, null, 8, ["is-checkbox-visible", "module-name", "is-checkbox-checked"]),
          (_ctx.ShowCustomCategoryLinkedParticipantListView() && _ctx.EnableLinkParticipantToInformation === true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                _cache[33] || (_cache[33] = _createElementVNode("hr", { class: "margin-top-30 margin-bottom-25" }, null, -1)),
                _createVNode(_component_CustomCategoryLinkedParticipantListView, {
                  "header-text": _ctx.localSelectedBaseCustomCategoryRenderer.LinkParticipantProfileText,
                  "no-profile-text": _ctx.SetProfileText,
                  "btn-text": _ctx.GetButtonText(),
                  "show-add-button": _ctx.ShowAddButton,
                  "selected-custom-type-id": _ctx.localCustomCategoryItem.CustomCategoryId,
                  "selected-custom-category-item": _ctx.localCustomCategoryItem,
                  "all-participants": _ctx.localAllParticipants,
                  onLinkedCategoryAddProgramNotification: _cache[21] || (_cache[21] = ($event: any) => (_ctx.SendNotificationRequestForCustomCategoryDetails($event)))
                }, null, 8, ["header-text", "no-profile-text", "btn-text", "show-add-button", "selected-custom-type-id", "selected-custom-category-item", "all-participants"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.IsEventWebpagePFEnabledAndInformatiomTypeSponsorOrSpeaker())
            ? (_openBlock(), _createElementBlock("hr", _hoisted_44))
            : (_openBlock(), _createElementBlock("hr", _hoisted_45)),
          _createElementVNode("div", _hoisted_46, [
            _createVNode(_component_CustomCategoryLinkedProgramListView, {
              "linked-program": _ctx.localCustomCategoryItem.LinkedPrograms,
              "selected-custom-category-id": _ctx.localCustomCategoryItem.CustomCategoryId,
              onShowAttachProgram: _cache[22] || (_cache[22] = ($event: any) => (_ctx.showAttachProgram($event)))
            }, null, 8, ["linked-program", "selected-custom-category-id"])
          ]),
          (_ctx.ShowMultimediaSection())
            ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                _cache[34] || (_cache[34] = _createElementVNode("hr", { class: "margin-35-0" }, null, -1)),
                _createElementVNode("div", _hoisted_48, [
                  _createVNode(_component_CustomCategoryMultimediaListView, {
                    "custom-category-medias": _ctx.localCustomCategoryItem.CustomCategoryMedias,
                    "selected-custom-category-id": _ctx.localCustomCategoryItem.CustomCategoryId,
                    onCustomCategoryMultimediaItemShowImageComponentOperation: _cache[23] || (_cache[23] = ($event: any) => (_ctx.CustomCategoryMultimediaItemShowImageUploadComponent($event)))
                  }, null, 8, ["custom-category-medias", "selected-custom-category-id"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showImageProcessComp())
      ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
          _createVNode(_component_ImageProcess, {
            "featured-image-full-src": _ctx.GetfeaturedimageFullUrl(),
            "featuredimage-src": _ctx.GetFeaturedImageUrl(),
            "featured-image-width": _ctx.localCustomCategoryItem.FeaturedImageWidth,
            "featured-image-height": _ctx.localCustomCategoryItem.FeaturedImageHeight,
            "image-src": _ctx.GetImageUrl(),
            "image-full-src": _ctx.GetImageFullUrl(),
            "show-featured-image": _ctx.ShowFeatureImage(),
            "heading-title": _ctx.GetImageProcessHeadingTitle(),
            "has-featured-image": _ctx.CheckFeaturedImage(),
            "left-position": _ctx.localCustomCategoryItem.LeftPosition,
            "top-position": _ctx.localCustomCategoryItem.TopPosition,
            "module-name": _ctx.CustomCategoryModule,
            "show-title-below-featured-image": _ctx.localCustomCategoryItem.ShowFeatureImageBelowTitle,
            "sub-module-name": _ctx.subModuleName,
            "is-full-image": _ctx.localCustomCategoryItem.IsFullImage,
            "show-full-image-option": _ctx.showFullImageOption,
            onCheckIsFullImage: _ctx.CheckIsFullImage,
            onSetImageIconUrl: _ctx.SetImageIconUrl,
            onSelectBack: _ctx.clickOnImage,
            onSetFeaturedImageIconUrl: _ctx.SetFeaturedImageIconUrl,
            onShowTitleBelowFeaturedImage: _ctx.ShowInformationtitleBelowFeaturedImage
          }, null, 8, ["featured-image-full-src", "featuredimage-src", "featured-image-width", "featured-image-height", "image-src", "image-full-src", "show-featured-image", "heading-title", "has-featured-image", "left-position", "top-position", "module-name", "show-title-below-featured-image", "sub-module-name", "is-full-image", "show-full-image-option", "onCheckIsFullImage", "onSetImageIconUrl", "onSelectBack", "onSetFeaturedImageIconUrl", "onShowTitleBelowFeaturedImage"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowAddProgramSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
          _createVNode(_component_AddProgramWithEntity, {
            "selected-entity-id": _ctx.localCustomCategoryItem.CustomCategoryId,
            "all-programs": _ctx.localAllPrograms,
            "is-bulk-operation": false,
            "entity-type": _ctx.entityType,
            onSelectBack: _cache[24] || (_cache[24] = ($event: any) => (_ctx.showAttachProgram($event))),
            onLinkedCategoryAddProgramNotification: _cache[25] || (_cache[25] = ($event: any) => (_ctx.SendNotificationRequestForCustomCategoryDetails($event)))
          }, null, 8, ["selected-entity-id", "all-programs", "entity-type"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowShareWithOtherEventsSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
          _createVNode(_component_CustomCategoryShareWithOtherEvents, {
            "selected-custom-category-id": _ctx.localCustomCategoryItem.CustomCategoryId,
            "all-events": _ctx.localAllEvents,
            "is-bulk-operation": false,
            "linked-events": _ctx.localCustomCategoryItem.LinkedEvents,
            "is-shared-video-warning-visible-for-share-with-other-event": _ctx.isSharedVideoMessageShow,
            onSelectBack: _cache[26] || (_cache[26] = ($event: any) => (_ctx.showAttachWithSharedEvents($event))),
            onCustomCategorySharedEventNotification: _cache[27] || (_cache[27] = ($event: any) => (_ctx.SendNotificationRequestForSharedEvents($event)))
          }, null, 8, ["selected-custom-category-id", "all-events", "linked-events", "is-shared-video-warning-visible-for-share-with-other-event"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowFileUploadSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
          _createVNode(_component_FileUpload, {
            "heading-title": _ctx.GetMultimediaFileUploadHeadingTitle(),
            "image-src": _ctx.GetMultimediaImageUrl(),
            "image-full-src": _ctx.GetMultimediaImageFullUrl(),
            "accepted-file-types": _ctx.GetAcceptedFileTypes(),
            "heading-sub-title": _ctx.GetMultimediaFileUploadSubHeadingTitle(),
            "file-extensions": _ctx.GetFileExtensions(),
            validationtype: _ctx.GetValidationType(),
            "icon-image-size-width": _ctx.GetIconImageSize(),
            "icon-image-size-height": _ctx.GetIconImageSize(),
            "validation-message": _ctx.GetValidationMessage(),
            type: _ctx.GetMultimediaImageType(),
            "module-type": _ctx.CustomCategoryModule,
            "sub-module-name": _ctx.subModuleName,
            onSelectBack: _ctx.CustomCategoryMultimediaItemFileUploadCompleted,
            onSetImageIconUrl: _ctx.SetMultimediaIconUrl,
            onPreviousIconUrl: _ctx.GetPreviousIconUrl
          }, null, 8, ["heading-title", "image-src", "image-full-src", "accepted-file-types", "heading-sub-title", "file-extensions", "validationtype", "icon-image-size-width", "icon-image-size-height", "validation-message", "type", "module-type", "sub-module-name", "onSelectBack", "onSetImageIconUrl", "onPreviousIconUrl"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showSearchModule)
      ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
          _createVNode(_component_CommonSearchComponent, {
            "search-listing": _ctx.searchListing,
            "search-information-text": _ctx.searchNoticeLineText,
            "search-placeholder": _ctx.searchPlaceholder,
            "search-title": _ctx.searchTitle,
            "is-multiple-participant": _ctx.isMultipleParticipant,
            "search-linked-listing": _ctx.searchLinkedListing,
            "selected-customer-type-id": _ctx.selectedCustomerTypeId,
            "no-participant-found-text": _ctx.noParticipantFoundText,
            "selected-customer-category-item": _ctx.localCustomCategoryItem,
            "has-participant": _ctx.hasParticipant,
            count: _ctx.participantsCount,
            onCloseClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.ClickClose($event)))
          }, null, 8, ["search-listing", "search-information-text", "search-placeholder", "search-title", "is-multiple-participant", "search-linked-listing", "selected-customer-type-id", "no-participant-found-text", "selected-customer-category-item", "has-participant", "count"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowAddVideoSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
          _createVNode(_component_MuxVideo, {
            "selected-vod-base": _ctx.selectedVodRenderer,
            onSetVodDetails: _cache[29] || (_cache[29] = ($event: any) => (_ctx.SetVodDetailId($event))),
            onVodsaveupdate: _cache[30] || (_cache[30] = ($event: any) => (_ctx.vodsaveupdate()))
          }, null, 8, ["selected-vod-base"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowPreRecordedVideoChartSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
          _createVNode(_component_PreRecordedMeetingHighcharts, {
            "video-detail": _ctx.GetVodDetailValues(),
            "video-on-demand-metrics": _ctx.localCustomCategoryItem.VodDetail.VodVideoAnalytics,
            "stream-type": _ctx.localCustomCategoryItem.VodDetail.VodStreamingType
          }, null, 8, ["video-detail", "video-on-demand-metrics", "stream-type"])
        ]))
      : _createCommentVNode("", true)
  ]))
}