import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modalpopup" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "modal",
  "data-backdrop": "static",
  "data-keyboard": "false"
}
const _hoisted_4 = { class: "modal-dialog" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "body-content" }
const _hoisted_9 = { class: "modal-footer" }
const _hoisted_10 = ["hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.localShowModalPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_Transition, { name: "modal" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.messageIconType !== undefined && _ctx.messageIconType !== '')
                      ? (_openBlock(), _createElementBlock("i", {
                          key: 0,
                          class: _normalizeClass([_ctx.getFontAwasomeIcon(_ctx.messageIconType)]),
                          "aria-hidden": "true"
                        }, null, 2))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", {
                        class: "body-heading",
                        innerHTML: _ctx.$t(_ctx.bodyHeading)
                      }, null, 8, _hoisted_7),
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.bodyContent), 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-secondary btn-size-default",
                        hidden: !_ctx.localshowCancelButton,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.Cancel();})
                      }, _toDisplayString(_ctx.localcancelButtonText), 9, _hoisted_10),
                      _createElementVNode("button", {
                        class: "btn btn-primary btn-size-default",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.clickYes();})
                      }, _toDisplayString(_ctx.localConfirmButtonText), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}