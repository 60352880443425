import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, vModelSelect as _vModelSelect, normalizeStyle as _normalizeStyle, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "dvFileupload",
  class: "edit-pane-height scroll-top"
}
const _hoisted_2 = { class: "inline-flex100 align-items-center" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "main-title-width" }
const _hoisted_5 = {
  key: 0,
  class: "main-heading primary-heading"
}
const _hoisted_6 = {
  key: 1,
  class: "main-heading primary-heading"
}
const _hoisted_7 = {
  key: 2,
  class: "main-heading primary-heading"
}
const _hoisted_8 = {
  key: 1,
  class: "div-pricing-info border-radius-10"
}
const _hoisted_9 = { class: "color-red" }
const _hoisted_10 = { class: "margin-top-20" }
const _hoisted_11 = { class: "color-red" }
const _hoisted_12 = { class: "top-margin-in-fields" }
const _hoisted_13 = { class: "step-description" }
const _hoisted_14 = {
  key: 0,
  class: "upload-button"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 1,
  class: "top-margin-in-fields"
}
const _hoisted_17 = { class: "bold-text" }
const _hoisted_18 = {
  key: 2,
  class: "top-margin-in-fields"
}
const _hoisted_19 = { class: "bold-text" }
const _hoisted_20 = { class: "display-flex" }
const _hoisted_21 = {
  key: 0,
  class: "progress top-margin-in-fields"
}
const _hoisted_22 = ["id", "aria-valuenow"]
const _hoisted_23 = { key: 1 }
const _hoisted_24 = {
  key: 0,
  class: "percentage-progress"
}
const _hoisted_25 = ["id"]
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "inline-flex100 margin-top-13" }
const _hoisted_28 = { class: "margin-left-10" }
const _hoisted_29 = {
  key: 3,
  class: "caption-error-message"
}
const _hoisted_30 = {
  key: 2,
  class: "div-streaming-cost font-size-13"
}
const _hoisted_31 = ["src"]
const _hoisted_32 = ["href"]
const _hoisted_33 = {
  key: 3,
  class: "includeVideoInfoMsg"
}
const _hoisted_34 = {
  key: 4,
  class: "top-margin-in-fields"
}
const _hoisted_35 = { class: "step-description" }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = ["index"]
const _hoisted_38 = { class: "inline-flex100" }
const _hoisted_39 = ["value"]
const _hoisted_40 = ["id"]
const _hoisted_41 = { class: "dropdown inline-flex valign" }
const _hoisted_42 = ["disabled"]
const _hoisted_43 = ["src"]
const _hoisted_44 = { class: "dropdown-menu" }
const _hoisted_45 = ["onClick"]
const _hoisted_46 = ["onClick"]
const _hoisted_47 = ["id"]
const _hoisted_48 = { class: "margin-left-10" }
const _hoisted_49 = ["id"]
const _hoisted_50 = { class: "ajax-loader" }
const _hoisted_51 = ["src"]
const _hoisted_52 = ["id"]
const _hoisted_53 = { class: "inline-flex100" }
const _hoisted_54 = {
  class: "file",
  style: {"margin-top":"0px"}
}
const _hoisted_55 = ["name", "onChange"]
const _hoisted_56 = { class: "file-custom border-radius-10" }
const _hoisted_57 = { class: "hyperLink margin-left-15" }
const _hoisted_58 = { key: 1 }
const _hoisted_59 = { class: "inline-flex100" }
const _hoisted_60 = { class: "width25 margin-top-10" }
const _hoisted_61 = {
  for: "Type",
  class: "bold-text"
}
const _hoisted_62 = ["disabled"]
const _hoisted_63 = ["value"]
const _hoisted_64 = { class: "width68 leftMargin margin-top-40" }
const _hoisted_65 = { class: "fileUploadDiv file-upload-control inline-flex100" }
const _hoisted_66 = { class: "file-upload-margin" }
const _hoisted_67 = {
  class: "file",
  style: {"margin-top":"0px"}
}
const _hoisted_68 = ["disabled"]
const _hoisted_69 = ["disabled"]
const _hoisted_70 = {
  key: 0,
  class: "allow-extension-msg"
}
const _hoisted_71 = {
  key: 1,
  class: "caption-error-message"
}
const _hoisted_72 = { class: "hyperLink" }
const _hoisted_73 = { class: "top-margin-in-fields" }
const _hoisted_74 = ["disabled"]
const _hoisted_75 = { key: 5 }
const _hoisted_76 = { class: "step-description" }
const _hoisted_77 = { class: "row" }
const _hoisted_78 = {
  key: 0,
  class: "col-xl-4 inline-flex100 visible-inline-block"
}
const _hoisted_79 = { class: "margin-right-15 bold-text" }
const _hoisted_80 = ["disabled"]
const _hoisted_81 = ["value"]
const _hoisted_82 = {
  key: 0,
  class: "margin-right-3 bold-text"
}
const _hoisted_83 = { class: "VOD-start-datepicker" }
const _hoisted_84 = { class: "col-xl-4 inline-flex100 visible-inline-block" }
const _hoisted_85 = { class: "margin-right-3 bold-text" }
const _hoisted_86 = { class: "" }
const _hoisted_87 = { class: "VOD-end-datepicker" }
const _hoisted_88 = {
  key: 0,
  class: "saveCancelButtons visible-flex"
}
const _hoisted_89 = {
  key: 1,
  class: "visible-flex saved-msg"
}
const _hoisted_90 = { class: "margin-left-10" }
const _hoisted_91 = {
  key: 0,
  class: "caption-error-message"
}
const _hoisted_92 = {
  key: 1,
  class: "caption-error-message"
}
const _hoisted_93 = { class: "margin-top-10 dates-saved" }
const _hoisted_94 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_MeetappPricingDetails = _resolveComponent("MeetappPricingDetails")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.modalPopupVisible)
        ? (_openBlock(), _createBlock(_component_ModalPopup, {
            key: 0,
            "show-modal-popup": _ctx.modalPopupVisible,
            "header-content": _ctx.modalPopupHeader,
            "body-heading": _ctx.modalPopupContentHeading,
            "body-content": _ctx.modalPopupContent,
            "show-cancel-button": _ctx.modalPopupShowCancelButton,
            "cancel-button-text": _ctx.modalPopupCancelButtonText,
            "confirm-button-text": _ctx.modalPopupConfirmButtonText,
            "show-close-button": _ctx.showCloseButton,
            onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
          }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            disabled: !_ctx.isCancelEnabled,
            class: "btn btn-secondary btn-cancel-addvideo btn-size-default",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Cancel()))
          }, _toDisplayString(_ctx.$t("Button.Back")), 9, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.ShowSecondStep())
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.PreRecorded.AddVideo")), 1))
            : _createCommentVNode("", true),
          (_ctx.localSelectedVodBase.SubModuleType === 4)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.PreRecorded.RTMP")), 1))
            : (_ctx.localSelectedVodBase.SubModuleType === 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t(
                "VirtualMeetings.ExternalStreams.PreRecorded.MeetAppLiveRecording"
              )), 1))
              : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.showPricingDetailText())
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t("VirtualMeetings.PricingDetailText")) + " " + _toDisplayString(_ctx.videoMinutesOfDurationNotCharged) + " " + _toDisplayString(_ctx.$t("VirtualMeetings.PricingDetailText1")), 1),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("Asteric")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("Asteric")), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("VirtualMeetings.PricingDetailText3")) + " " + _toDisplayString(_ctx.videoMinutesOfDurationNotCharged) + " " + _toDisplayString(_ctx.$t("VirtualMeetings.PricingDetailText4")) + " ", 1),
                _createElementVNode("span", {
                  class: _normalizeClass(["hyper-link description", { 'disabled-cotroller': _ctx.IsDisablePricingPopUp }]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ShowVodPricingPopUp()))
                }, _toDisplayString(_ctx.$t("VirtualMeetings.PricingSchedule")), 3)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.Step1")) + "  ", 1),
        _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.Step1Heading), 1),
        _createElementVNode("input", {
          id: "picker",
          type: "file",
          style: {"display":"none"},
          onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getUploadUrl()))
        }, null, 32),
        (!_ctx.isUploadingInProgress)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-size-default",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.UploadVideoButtonClick()))
              }, [
                _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_15),
                _createTextVNode(_toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.VideoUploadButtonText")), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.VodStatus !== '' && !_ctx.isCompleted)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.VodStatus), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isCompleted)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.VideoStatusUploaded")), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_20, [
          (_ctx.isUploadingInProgress)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("div", {
                  id: _ctx.progressBarId,
                  class: _normalizeClass(["progress-bar progress-bar-animated", { 'progress-bar-striped': _ctx.isProgressBarStriped }]),
                  role: "progressbar",
                  "aria-valuenow": _ctx.percentageUploaded,
                  "aria-valuemin": "0",
                  "aria-valuemax": "100",
                  style: {"width":"0%"}
                }, null, 10, _hoisted_22)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isUploadingInProgress && !_ctx.isVodStatusProcess)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                (!_ctx.isCompleted)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                      _createElementVNode("label", {
                        id: _ctx.progressBarId + '_Percent'
                      }, null, 8, _hoisted_25),
                      _createElementVNode("a", {
                        class: "hyperLink margin-left-4",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.CancelUploadingOfVideoClicked()))
                      }, _toDisplayString(_ctx.$t("CancelButton")), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.CaptionDone")), 1),
                        _cache[17] || (_cache[17] = _createElementVNode("i", { class: "fa fa-check-circle check-circle margin-top-10" }, null, -1))
                      ])
                    ]))
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.videoFileTypeErrorText !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(_ctx.videoFileTypeErrorText), 1))
          : _createCommentVNode("", true)
      ]),
      ((_ctx.showPricingDetailText() && _ctx.showVodStreamingCostMessage()) || _ctx.IsVideoGreaterThanTenMins)
        ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: _ctx.warningIcon,
                alt: "Warning",
                class: "margin-right-15"
              }, null, 8, _hoisted_31)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, [
                _createTextVNode(_toDisplayString(_ctx.$t("VirtualMeetings.StreamingCost")) + " ", 1),
                _createElementVNode("a", {
                  class: "hyperLink",
                  href: _ctx.MailToText
                }, _toDisplayString(_ctx.$t("VirtualMeetings.StreamingCost.ContactUs")), 9, _hoisted_32),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("VirtualMeetings.StreamingCost2")), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _cache[23] || (_cache[23] = _createElementVNode("hr", null, null, -1)),
      (_ctx.isCaptionMsgShow)
        ? (_openBlock(), _createElementBlock("span", _hoisted_33, [
            _cache[18] || (_cache[18] = _createElementVNode("i", {
              class: "fa fa-exclamation-triangle ml-2 mr-4",
              "aria-hidden": "true"
            }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.SharedCaptionMsg")), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.ShowSecondStep())
        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.Step2")) + " ", 1),
            _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.AddCaption")), 1),
            (_ctx.localCaptions.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localCaptions, (captionItem, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: captionItem.Id,
                      index: index,
                      class: "top-margin-in-fields"
                    }, [
                      _withDirectives(_createElementVNode("div", _hoisted_38, [
                        _createElementVNode("div", null, [
                          _createElementVNode("input", {
                            type: "text",
                            class: "form-control caption-type",
                            maxlength: "100",
                            value: captionItem.LanguageName
                          }, null, 8, _hoisted_39)
                        ]),
                        (_ctx.captionObjectForEdit.Id != captionItem.Id)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              id: captionItem.Id + '_Manage',
                              class: "manage-div-width manage-button visible-manage-button display-flex align-items-center"
                            }, [
                              _createElementVNode("div", _hoisted_41, [
                                _createElementVNode("div", {
                                  "data-toggle": "dropdown",
                                  disabled: 
                      _ctx.recentlyAddedTrackId === captionItem.Id &&
                        !_ctx.ShowCaptionSuccess
                    
                                }, [
                                  _createElementVNode("img", {
                                    src: _ctx.manageIcon,
                                    class: "manage-icon cursor-default"
                                  }, null, 8, _hoisted_43)
                                ], 8, _hoisted_42),
                                _createElementVNode("ul", _hoisted_44, [
                                  _createElementVNode("li", {
                                    class: "manage-list-item",
                                    onClick: ($event: any) => (_ctx.ReplaceCaptionFile(captionItem))
                                  }, [
                                    _createElementVNode("a", null, _toDisplayString(_ctx.$t("Button.Replace")), 1)
                                  ], 8, _hoisted_45),
                                  _createElementVNode("li", {
                                    class: "manage-list-item",
                                    onClick: ($event: any) => (_ctx.DeleteCaptionClicked(captionItem))
                                  }, [
                                    _createElementVNode("a", null, _toDisplayString(_ctx.$t("Button.Delete")), 1)
                                  ], 8, _hoisted_46)
                                ])
                              ])
                            ], 8, _hoisted_40))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", null, [
                          (
                    _ctx.recentlyAddedTrackId === captionItem.Id && _ctx.ShowCaptionSuccess
                  )
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                id: captionItem.Id + '_Done',
                                class: "inline-flex100"
                              }, [
                                _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.CaptionSaved")), 1),
                                _cache[19] || (_cache[19] = _createElementVNode("i", { class: "fa fa-check-circle check-circle margin-top-9" }, null, -1))
                              ], 8, _hoisted_47))
                            : _createCommentVNode("", true),
                          (
                    _ctx.recentlyAddedTrackId === captionItem.Id && !_ctx.ShowCaptionSuccess
                  )
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                id: captionItem.Id + '_Procerssing',
                                class: "inline-flex100"
                              }, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("div", _hoisted_50, [
                                    _createElementVNode("img", {
                                      class: "img-responsive ajax-loader-img",
                                      src: _ctx.loadingImage,
                                      alt: "Wait"
                                    }, null, 8, _hoisted_51)
                                  ])
                                ])
                              ], 8, _hoisted_49))
                            : _createCommentVNode("", true)
                        ]),
                        (_ctx.captionObjectForEdit.Id == captionItem.Id)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              id: captionItem.Id + '_FileUpload'
                            }, [
                              _createElementVNode("div", _hoisted_53, [
                                _createElementVNode("label", _hoisted_54, [
                                  _createElementVNode("input", {
                                    id: "uploadImage",
                                    ref_for: true,
                                    ref: "file",
                                    type: "file",
                                    name: _ctx.uploadImage,
                                    onChange: ($event: any) => (
                        _ctx.onCaptionFileReplace(
                          $event.target.name,
                          $event.target.files,
                          captionItem
                        )
                      )
                                  }, null, 40, _hoisted_55),
                                  _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.Iconfilename), 1)
                                ]),
                                _createElementVNode("div", {
                                  class: "cancel-replace-action",
                                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.CancelReplaceCaption()))
                                }, [
                                  _createElementVNode("a", _hoisted_57, _toDisplayString(_ctx.$t("CancelButton")), 1)
                                ])
                              ])
                            ], 8, _hoisted_52))
                          : _createCommentVNode("", true)
                      ], 512), [
                        [_vShow, !captionItem.IsDeleted]
                      ])
                    ], 8, _hoisted_37))
                  }), 128)),
                  _cache[20] || (_cache[20] = _createElementVNode("div", null, null, -1))
                ]))
              : _createCommentVNode("", true),
            (_ctx.isEditPaneVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                  _createElementVNode("div", _hoisted_59, [
                    _createElementVNode("div", _hoisted_60, [
                      _createElementVNode("label", _hoisted_61, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.CaptionHeading")), 1),
                      _withDirectives(_createElementVNode("select", {
                        id: "selMeetingTypes",
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
                        class: _normalizeClass(["form-control selectBox select-language", {'outline-0': !_ctx.isVideoready}]),
                        disabled: !_ctx.isVideoready
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableLanguages.arrSupportedLanguages, (lang) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: lang.Id,
                            value: lang
                          }, _toDisplayString(lang.LanguageName), 9, _hoisted_63))
                        }), 128))
                      ], 10, _hoisted_62), [
                        [_vModelSelect, _ctx.selectedLanguage]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_64, [
                      _createElementVNode("div", _hoisted_65, [
                        _createElementVNode("div", _hoisted_66, [
                          _createElementVNode("label", _hoisted_67, [
                            _createElementVNode("input", {
                              id: "uploadImage",
                              ref: "file",
                              type: "file",
                              disabled: !_ctx.isVideoready,
                              class: _normalizeClass({ 'disabled-cotroller': !_ctx.isVideoready }),
                              onChange: _cache[8] || (_cache[8] = ($event: any) => (
                        _ctx.onCaptionFileSelected(
                          $event.target.name,
                          $event.target.files
                        )
                      ))
                            }, null, 42, _hoisted_68),
                            _createElementVNode("span", {
                              class: "file-custom border-radius-10",
                              disabled: !_ctx.isVideoready,
                              style: _normalizeStyle([_ctx.isVideoready ? {'cursor': 'pointer'}: ''])
                            }, _toDisplayString(_ctx.Iconfilename), 13, _hoisted_69)
                          ]),
                          (_ctx.fileExtensions != '')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_70, _toDisplayString(_ctx.$t("AllowedExtension")) + " : " + _toDisplayString(_ctx.fileExtensions), 1))
                            : _createCommentVNode("", true),
                          (_ctx.errorText !== '')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_71, _toDisplayString(_ctx.errorText), 1))
                            : _createCommentVNode("", true)
                        ]),
                        (_ctx.showCaptionsCancelButton)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["margin-top-8", { 'disabled-cotroller': !_ctx.isVideoready }]),
                              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.HideCaptionEditPane(false)))
                            }, [
                              _createElementVNode("a", _hoisted_72, _toDisplayString(_ctx.$t("CancelButton")), 1)
                            ], 2))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_73, [
                _createElementVNode("button", {
                  class: "add-more-link-button",
                  disabled: !_ctx.showCaptionsButton,
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.ShowCaptionEditPane(true)))
                }, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.AddMoreCaptions")), 9, _hoisted_74)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.ShowSecondStep())
        ? (_openBlock(), _createElementBlock("hr", _hoisted_75))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("label", null, _toDisplayString(_ctx.RenameStep()) + " ", 1),
        _createElementVNode("label", _hoisted_76, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.Publish")), 1),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_77, [
            (_ctx.isDropDownVisible())
              ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                  _createElementVNode("label", _hoisted_79, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.From")), 1),
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedPublishOption) = $event)),
                    disabled: !_ctx.isVideoready,
                    class: _normalizeClass(["form-control selectBox", {'outline-0': !_ctx.isVideoready}]),
                    onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.selectOption(true)))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localSelectedVodBase.PublishItemList, (option) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: option.Value,
                        value: option.Value
                      }, _toDisplayString(option.Text), 9, _hoisted_81))
                    }), 128))
                  ], 42, _hoisted_80), [
                    [_vModelSelect, _ctx.selectedPublishOption]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.ShowPublishDateOption())
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["col-xl-4", [
                { 'margin-top-30': _ctx.selectedPublishOption !== _ctx.unPublishedOption },
              ]])
                }, [
                  (_ctx.selectedPublishOption === _ctx.unPublishedOption)
                    ? (_openBlock(), _createElementBlock("label", _hoisted_82, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.From")), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_83, [
                    _createVNode(_component_Datepicker, {
                      id: "publishDatedatetimepicker",
                      date: _ctx.GetDateForPublish(),
                      "full-day-time-format": _ctx.CheckTimeFormat(),
                      disable: _ctx.SetPublishDateDisable(),
                      "date-only": _ctx.isDateOnly,
                      "id-value": _ctx.$t('VirtualMeetings.AddVideo.From'),
                      onSelectedDate: _ctx.SetVideoPublishDate
                    }, null, 8, ["date", "full-day-time-format", "disable", "date-only", "id-value", "onSelectedDate"])
                  ])
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_84, [
              _createElementVNode("label", _hoisted_85, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.AvailableTill")), 1),
              _createElementVNode("i", {
                class: "fa fa-info-circle available-till-info",
                "aria-hidden": "true",
                onMouseover: _cache[13] || (_cache[13] = ($event: any) => (_ctx.getInformationString(true))),
                onMouseleave: _cache[14] || (_cache[14] = ($event: any) => (_ctx.getInformationString(false)))
              }, null, 32),
              _withDirectives(_createElementVNode("div", { class: "information-icon-div" }, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.VideoUnavailable")), 513), [
                [_vShow, _ctx.isVideoUnavailableInfo]
              ]),
              _createElementVNode("div", _hoisted_86, [
                _createElementVNode("div", _hoisted_87, [
                  _createVNode(_component_Datepicker, {
                    id: "storageAvailabilitydatetimepicker",
                    date: _ctx.GetStorageAvailabilityDate(),
                    "full-day-time-format": _ctx.CheckTimeFormat(),
                    disable: !_ctx.isVideoready,
                    "date-only": true,
                    "id-value": _ctx.$t('VirtualMeetings.AddVideo.VideoUnavailable'),
                    onSelectedDate: _ctx.SetStorageAvailabilityDate
                  }, null, 8, ["date", "full-day-time-format", "disable", "id-value", "onSelectedDate"])
                ]),
                (_ctx.saveCancelButtonsShow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_88, [
                      _createElementVNode("i", {
                        class: "fa fa-check-circle save-cancel-btn-icon",
                        onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.SavedSuccessfully()))
                      }),
                      _createElementVNode("i", {
                        class: "fa fa-times-circle save-cancel-btn-icon",
                        onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.CancelIcon()))
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isDatesSaved)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                      _createElementVNode("label", _hoisted_90, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.CaptionSaved")), 1),
                      _cache[21] || (_cache[21] = _createElementVNode("i", { class: "fa fa-check-circle check-circle-date-saved margin-top-9" }, null, -1))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        (_ctx.isStorageAvailabilityDateError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_91, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.StorageTillDateValidationMsg")), 1))
          : _createCommentVNode("", true),
        (_ctx.isPublishedDateError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_92, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.PublishedDateValidationMsg")), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_93, [
          _cache[22] || (_cache[22] = _createElementVNode("div", null, [
            _createElementVNode("i", { class: "fa fa-exclamation-triangle margin-right-10" })
          ], -1)),
          _createElementVNode("div", null, [
            _createElementVNode("span", {
              class: "italic-text",
              innerHTML: _ctx.updateStoragePricing
            }, null, 8, _hoisted_94)
          ])
        ], 512), [
          [_vShow, _ctx.storagePricingInfo]
        ])
      ])
    ]),
    (_ctx.showVodPricingDetailsPopup)
      ? (_openBlock(), _createBlock(_component_MeetappPricingDetails, {
          key: 0,
          "show-pricing-popup": _ctx.showVodPricingDetailsPopup,
          "product-pricing-information": _ctx.vodLivePricingInformation,
          "pricing-popup-type": _ctx.pricingPopupType
        }, null, 8, ["show-pricing-popup", "product-pricing-information", "pricing-popup-type"]))
      : _createCommentVNode("", true)
  ]))
}