import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "dvAddPrograms",
  class: "flex-container"
}
const _hoisted_2 = { class: "inline-flex100 align-items-center" }
const _hoisted_3 = { class: "width70" }
const _hoisted_4 = { class: "main-heading primary-heading" }
const _hoisted_5 = { class: "clear-both margin-top-30 margin-bottom-10" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "input_container" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "fa fa-search video-type-icon" }
const _hoisted_10 = { class: "notic" }
const _hoisted_11 = {
  key: 0,
  class: "no-participant"
}
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "item-position-from-image valign-parent50 width80" }
const _hoisted_14 = { class: "valign" }
const _hoisted_15 = { class: "font-weight-bold" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "width30 text-right" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!

  return (_openBlock(), _createElementBlock("div", {
    id: "dvAddParticipants",
    class: _normalizeClass({'scroll-top' :!_ctx.isMobileView})
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ClickClose()))
            }, _toDisplayString(_ctx.$t('Button.Close')), 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.localSearchTitle), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localSearchText) = $event)),
                type: "text",
                placeholder: _ctx.localSearchPlaceholder,
                class: "form-control input-field search-input",
                onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onKeySearch($event)))
              }, null, 40, _hoisted_8), [
                [_vModelText, _ctx.localSearchText]
              ]),
              _withDirectives(_createElementVNode("i", _hoisted_9, null, 512), [
                [_vShow, _ctx.localSearchText === '']
              ]),
              _withDirectives(_createElementVNode("i", {
                class: "fa fa-times video-type-icon",
                "aria-hidden": "true",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ClearSearchText()))
              }, null, 512), [
                [_vShow, _ctx.localSearchText !== '']
              ])
            ]),
            _withDirectives(_createElementVNode("span", _hoisted_10, [
              _cache[4] || (_cache[4] = _createElementVNode("i", {
                class: "fa fa-info-circle fa-lg",
                "aria-hidden": "true"
              }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.localSearchNoticeLineText), 1)
            ], 512), [
              [_vShow, _ctx.showSearchNoticeText]
            ])
          ], 512), [
            [_vShow, _ctx.localHasParticipant === true]
          ])
        ]),
        (_ctx.localSearchItem !== undefined && _ctx.localSearchItem.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.noParticipantFoundText), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localSearchItem, (item) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: item.Id,
            class: "information-item"
          }, [
            _createElementVNode("div", null, [
              (_ctx.IsIconUrlValid(item.IconUrl))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: item.IconUrl,
                    class: "icon-Img round-image icon-img-custom"
                  }, null, 8, _hoisted_12))
                : _createCommentVNode("", true),
              (!_ctx.IsIconUrlValid(item.IconUrl))
                ? (_openBlock(), _createBlock(_component_avatar, {
                    key: 1,
                    background: _ctx.GetDefaultBackgroundColor(),
                    color: _ctx.GetDefaultForegroundColor(),
                    size: 60,
                    lighten: 40,
                    border: false,
                    name: _ctx.GetIntials(item.FirstName, item.LastName),
                    class: "display-contents icon-img-custom"
                  }, null, 8, ["background", "color", "name"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("span", _hoisted_15, _toDisplayString(item.Name), 1),
                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(item.Title) + " ", 1),
                  (item.Company !== null && item.Title !== null && item.Company !== '' && item.Title !== '')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, "-"))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(item.Company), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _withDirectives(_createElementVNode("button", {
                class: "btn btn-primary btn-size-default",
                onClick: ($event: any) => (_ctx.AddItem(item))
              }, _toDisplayString(_ctx.$t('Button.Add')), 9, _hoisted_18), [
                [_vShow, item.IsAdded === false && _ctx.localHideAddBtton === false]
              ]),
              _withDirectives(_createElementVNode("button", {
                class: "btn btn-secondary btn-size-default valign",
                onClick: ($event: any) => (_ctx.RemoveItem(item))
              }, _toDisplayString(_ctx.$t('Button.Remove')), 9, _hoisted_19), [
                [_vShow, item.IsAdded === true]
              ]),
              _withDirectives(_createElementVNode("button", {
                disabled: "",
                class: "btn btn-size-default btn-secondary-custom"
              }, _toDisplayString(_ctx.$t('Button.Add')), 513), [
                [_vShow, _ctx.localHideAddBtton === true && item.IsAdded === false]
              ])
            ])
          ])), [
            [_vShow, _ctx.localSearchItem !== undefined && _ctx.localSearchItem.length > 0]
          ])
        }), 128))
      ])
    ])
  ], 2))
}