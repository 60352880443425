import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "inline-flex100 align-items-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "main-title-width"
}
const _hoisted_4 = { class: "main-heading primary-heading" }
const _hoisted_5 = { class: "tab-content border-radius-10 margin-top-30" }
const _hoisted_6 = { class: "primary-heading" }
const _hoisted_7 = { class: "tab-content border-radius-10" }
const _hoisted_8 = { class: "primary-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HighchartPieChart = _resolveComponent("HighchartPieChart")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.showCancelButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-cancel-addvideo btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Cancel()))
            }, _toDisplayString(_ctx.$t("Button.Back")), 1)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.showCancelButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.Title), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('VirtualMeetings.Viewers')), 1),
      _createVNode(_component_HighchartPieChart, {
        "chart-data": _ctx.uniqueViewersChartData,
        "show-percentage": false
      }, null, 8, ["chart-data"])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('VirtualMeetings.WatchTime')), 1),
      _createVNode(_component_HighchartPieChart, {
        "chart-data": _ctx.watchedUsersChartData,
        "show-percentage": false
      }, null, 8, ["chart-data"])
    ])
  ]))
}