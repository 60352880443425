import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, toHandlers as _toHandlers, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "width100" }
const _hoisted_2 = { class: "main-heading primary-heading" }
const _hoisted_3 = { class: "text-right" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "cursor-pointer" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 4,
  class: "padding-top-20"
}
const _hoisted_8 = {
  key: 5,
  class: "image-full-round-btn-div"
}
const _hoisted_9 = { class: "logo-type" }
const _hoisted_10 = {
  key: 6,
  class: "width100 margin-top-30"
}
const _hoisted_11 = { class: "inline-flex100" }
const _hoisted_12 = { class: "batch-first-radio width30" }
const _hoisted_13 = { class: "upload-file-text-batch" }
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "radio-text" }
const _hoisted_16 = { class: "batch-other-radio width30" }
const _hoisted_17 = { class: "upload-file-text-batch" }
const _hoisted_18 = ["value"]
const _hoisted_19 = { class: "radio-text" }
const _hoisted_20 = { class: "batch-other-radio width40" }
const _hoisted_21 = { class: "upload-file-text-batch" }
const _hoisted_22 = ["value"]
const _hoisted_23 = { class: "radio-text" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "inline-flex100 margin-top-10" }
const _hoisted_26 = { class: "image-container" }
const _hoisted_27 = ["src"]
const _hoisted_28 = { class: "image-delete-container" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 1 }
const _hoisted_31 = { class: "width100" }
const _hoisted_32 = { class: "inline-flex100 display-button" }
const _hoisted_33 = { class: "upload-from-file-div" }
const _hoisted_34 = { class: "upload-file-text description" }
const _hoisted_35 = ["hidden"]
const _hoisted_36 = { key: 0 }
const _hoisted_37 = {
  key: 0,
  class: "build-icon"
}
const _hoisted_38 = { class: "upload-file-text description" }
const _hoisted_39 = {
  key: 0,
  class: "div-sub-heading"
}
const _hoisted_40 = {
  key: 1,
  class: "div-sub-heading"
}
const _hoisted_41 = {
  key: 2,
  class: "fileUploadDiv"
}
const _hoisted_42 = {
  key: 0,
  class: "image-recommended-text"
}
const _hoisted_43 = ["innerHTML"]
const _hoisted_44 = {
  key: 1,
  class: "image-recommended-text"
}
const _hoisted_45 = { class: "choose-image" }
const _hoisted_46 = { class: "file" }
const _hoisted_47 = ["name"]
const _hoisted_48 = { class: "file-custom border-radius-10" }
const _hoisted_49 = {
  key: 0,
  class: "fontawesomeStyleDiv"
}
const _hoisted_50 = { class: "label-color choose-icon-text" }
const _hoisted_51 = { class: "fontawesomeStyle" }
const _hoisted_52 = ["onClick"]
const _hoisted_53 = { class: "link-container" }
const _hoisted_54 = { class: "label-color width60" }
const _hoisted_55 = { class: "width40 text-right" }
const _hoisted_56 = { class: "color-update-container" }
const _hoisted_57 = { class: "inline-flex100" }
const _hoisted_58 = { class: "width-min-content" }
const _hoisted_59 = {
  key: 0,
  class: "color-container"
}
const _hoisted_60 = {
  class: "colorpicker-label-width",
  for: "Foregroundcolor"
}
const _hoisted_61 = {
  key: 1,
  class: "color-container"
}
const _hoisted_62 = {
  class: "colorpicker-label-width",
  for: "Backgroundcolor"
}
const _hoisted_63 = { class: "margin-top-40" }
const _hoisted_64 = { class: "swap-color" }
const _hoisted_65 = { key: 1 }
const _hoisted_66 = {
  key: 0,
  class: "errorText"
}
const _hoisted_67 = { key: 1 }
const _hoisted_68 = {
  key: 0,
  class: "dotted-line"
}
const _hoisted_69 = {
  key: 1,
  class: "div-sub-heading"
}
const _hoisted_70 = { class: "inline-flex margin-top-10" }
const _hoisted_71 = { class: "margin-top-10" }
const _hoisted_72 = ["src"]
const _hoisted_73 = { class: "featured-delete-container" }
const _hoisted_74 = { key: 0 }
const _hoisted_75 = { key: 1 }
const _hoisted_76 = {
  key: 0,
  class: "display-button"
}
const _hoisted_77 = { class: "featured-default-color-selected" }
const _hoisted_78 = { class: "cursor" }
const _hoisted_79 = { class: "featured-upload-selected" }
const _hoisted_80 = { class: "cursor" }
const _hoisted_81 = {
  key: 1,
  class: "display-button"
}
const _hoisted_82 = {
  key: 0,
  class: "featured-default-color-selected"
}
const _hoisted_83 = { class: "cursor" }
const _hoisted_84 = {
  key: 1,
  class: "featured-default-color-selected"
}
const _hoisted_85 = { class: "cursor" }
const _hoisted_86 = {
  key: 0,
  class: "featuredUploadDiv"
}
const _hoisted_87 = {
  key: 0,
  class: "image-recommended-text"
}
const _hoisted_88 = {
  key: 1,
  class: "image-recommended-text"
}
const _hoisted_89 = {
  key: 2,
  class: "image-recommended-text"
}
const _hoisted_90 = ["innerHTML"]
const _hoisted_91 = {
  key: 3,
  class: "image-recommended-text"
}
const _hoisted_92 = ["innerHTML"]
const _hoisted_93 = ["hidden"]
const _hoisted_94 = { class: "featured-image-upload" }
const _hoisted_95 = { class: "file" }
const _hoisted_96 = ["name"]
const _hoisted_97 = { class: "file-custom border-radius-10" }
const _hoisted_98 = {
  key: 5,
  class: "image-recommended-text"
}
const _hoisted_99 = { key: 1 }
const _hoisted_100 = { key: 2 }
const _hoisted_101 = { key: 3 }
const _hoisted_102 = { key: 4 }
const _hoisted_103 = { class: "margin-top-20" }
const _hoisted_104 = {
  key: 0,
  class: "errorText"
}
const _hoisted_105 = {
  key: 0,
  class: "footer"
}
const _hoisted_106 = { class: "parent-div-buttons" }
const _hoisted_107 = ["disabled"]
const _hoisted_108 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_VueModalPopUp = _resolveComponent("VueModalPopUp")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_colorpicker = _resolveComponent("colorpicker")!
  const _component_vuecroppie = _resolveComponent("vuecroppie")!
  const _component_featuredImage = _resolveComponent("featuredImage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'event-module': _ctx.moduleName === _ctx.EventModule }, "flex-container mobile-horizontal-scroll image-process-height"]),
    onKeypress: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onClick: _cache[26] || (_cache[26] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onMousemove: _cache[27] || (_cache[27] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args)))
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_loading, {
        "show-loading": _ctx.showLoading,
        "border-color": _ctx.loaderBorderColor
      }, null, 8, ["show-loading", "border-color"]),
      (_ctx.modalPopupVisible)
        ? (_openBlock(), _createBlock(_component_ModalPopup, {
            key: 0,
            "show-modal-popup": _ctx.modalPopupVisible,
            "header-content": _ctx.modalPopupHeading,
            "body-heading": _ctx.modalPopupBodyHeading,
            "body-content": _ctx.modalPopupContent,
            "show-close-button": _ctx.showCloseButton,
            onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
          }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
        : _createCommentVNode("", true),
      (_ctx.showVueModalPopUp)
        ? (_openBlock(), _createBlock(_component_VueModalPopUp, {
            key: 1,
            "modal-pop-up": _ctx.unsavedModalPopUp,
            onClose: _ctx.CloseModelPopup,
            onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SaveAndSwitchTab($event)))
          }, null, 8, ["modal-pop-up", "onClose"]))
        : _createCommentVNode("", true),
      ((_ctx.moduleName !== _ctx.EventModule && !_ctx.localIsFullImage) || _ctx.localShowFullImageOption)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["inline-flex100 align-items-center", { minHeight45: _ctx.isMobileView, minHeight50: !_ctx.isMobileView }])
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "btn btn-secondary btn-size-default",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickOnBack()))
              }, _toDisplayString(_ctx.$t("ImageProcess.back")), 1)
            ]),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headingTitle), 1)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-size-default",
                disabled: _ctx.isSaveButtonDisabled,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Save()))
              }, _toDisplayString(_ctx.$t("ImageProcess.save")), 9, _hoisted_4)
            ])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.moduleName === _ctx.EventModule || (_ctx.localIsFullImage && !_ctx.localShowFullImageOption))
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(["inline-flex100", { minHeight45: _ctx.isMobileView, minHeight50: !_ctx.isMobileView }])
          }, [
            _createElementVNode("div", {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickOnBack()))
            }, [
              _createElementVNode("label", _hoisted_5, [
                _createElementVNode("img", { src: _ctx.backIconFile }, null, 8, _hoisted_6),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("ImageProcess.back")), 1)
              ])
            ])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.visibleValidationSummary)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_ValidationSummary, {
              "error-fields": _ctx.validationErrorFields,
              onCloseClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.CloseValidationSummary($event)))
            }, null, 8, ["error-fields"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.localShowFullImageOption)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("label", {
              class: "logo-type",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.SetOptionGenereateIconSelected && _ctx.SetOptionGenereateIconSelected(...args)))
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localIsFullImage) = $event)),
                type: "radio",
                name: "selectImageType",
                class: "radio-button",
                value: true
              }, null, 512), [
                [_vModelRadio, _ctx.localIsFullImage]
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('FullLogoRecommended')), 1)
            ]),
            _createElementVNode("label", _hoisted_9, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localIsFullImage) = $event)),
                type: "radio",
                name: "selectImageType",
                class: "radio-button",
                value: false
              }, null, 512), [
                [_vModelRadio, _ctx.localIsFullImage]
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('RoundLogo')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isBulkOperation === true && _ctx.showFeaturedImage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("label", _hoisted_13, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedBatchImageRadiobtn) = $event)),
                    type: "radio",
                    name: "icon-or-featured",
                    value: _ctx.EditBoth
                  }, null, 8, _hoisted_14), [
                    [_vModelRadio, _ctx.selectedBatchImageRadiobtn]
                  ]),
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("Edit.Both")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("label", _hoisted_17, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectedBatchImageRadiobtn) = $event)),
                    type: "radio",
                    name: "icon-or-featured",
                    value: _ctx.EditIcon
                  }, null, 8, _hoisted_18), [
                    [_vModelRadio, _ctx.selectedBatchImageRadiobtn]
                  ]),
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("Edit.IconOnly")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("label", _hoisted_21, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedBatchImageRadiobtn) = $event)),
                    type: "radio",
                    name: "icon-or-featured",
                    value: _ctx.EditFeatured
                  }, null, 8, _hoisted_22), [
                    [_vModelRadio, _ctx.selectedBatchImageRadiobtn]
                  ]),
                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t("Edit.FeaturedImageOnly")), 1)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass({ 'div-image-select': _ctx.moduleName === _ctx.EventModule || _ctx.localIsFullImage })
      }, [
        (
            _ctx.selectedBatchImageRadiobtn !== _ctx.EditFeatured &&
              !_ctx.localOnlyShowFeatureImage
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("div", _mergeProps({
                    class: [_ctx.localIsFullImage ? 'custom-theme-upload-logo-div' :'imgdivDefault']
                  }, _toHandlers(
                  _ctx.optionGenereateIconSelected == 'false'
                    ? { click: () => _ctx.$refs.file.click() }
                    : {}
                , true)), [
                    _createElementVNode("img", {
                      class: _normalizeClass([_ctx.localIsFullImage ? 'custom-theme-upload-logo-imgcls':'imgcls',{
                    'round-image':
                      _ctx.moduleName == _ctx.ProgramModule ||
                      (_ctx.moduleName == _ctx.CustomCategoryModule && !_ctx.localIsFullImage) ||
                      _ctx.moduleName == _ctx.EventModule,
                  }]),
                      src: _ctx.GetCroppedImageIconUrl()
                    }, null, 10, _hoisted_27)
                  ], 16),
                  _createElementVNode("div", _hoisted_28, [
                    (_ctx.IsImageIconUrlExists() && _ctx.visibleDeleteLinkUnderImage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                          _createElementVNode("a", {
                            class: "delete",
                            href: "javascript:void(0);",
                            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.clearImageIconUrl()))
                          }, _toDisplayString(_ctx.$t("ImageProcess.delete")), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_30, _cache[28] || (_cache[28] = [
                          _createElementVNode("div", { href: "javascript:void(0)" }, "   ", -1)
                        ])))
                  ])
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("label", _hoisted_34, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.optionGenereateIconSelected) = $event)),
                          type: "radio",
                          name: "imageUpload",
                          value: "false",
                          class: "radio-button",
                          hidden: _ctx.moduleName === _ctx.EventModule || _ctx.localIsFullImage
                        }, null, 8, _hoisted_35), [
                          [_vModelRadio, _ctx.optionGenereateIconSelected]
                        ]),
                        (_ctx.moduleName !== _ctx.EventModule && !_ctx.localIsFullImage)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(_ctx.$t("ImageProcess.Uploadfromfile")), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    (_ctx.moduleName !== _ctx.EventModule && !_ctx.localIsFullImage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                          _createElementVNode("label", _hoisted_38, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.optionGenereateIconSelected) = $event)),
                              type: "radio",
                              name: "imageUpload",
                              value: "true",
                              class: "radio-button"
                            }, null, 512), [
                              [_vModelRadio, _ctx.optionGenereateIconSelected]
                            ]),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("ImageProcess.Buildanicon")), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.localIsFullImage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString(_ctx.$t("ImageProcess.lblSelectLogo")), 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_40, _toDisplayString(_ctx.$t("ImageProcess.lblSelectIcon")), 1)),
                  (_ctx.optionGenereateIconSelected == 'false')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                        (_ctx.localIsFullImage)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                              _createElementVNode("span", {
                                innerHTML: _ctx.GetUploadLogoWarning()
                              }, null, 8, _hoisted_43)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_44, _toDisplayString(_ctx.$t("ImageProcess.150pixelimagerecommended")), 1)),
                        _createElementVNode("div", _hoisted_45, [
                          _createElementVNode("label", _hoisted_46, [
                            _createElementVNode("input", {
                              id: "uploadImage",
                              ref: "file",
                              type: "file",
                              name: _ctx.uploadImage,
                              onChange: _cache[15] || (_cache[15] = ($event: any) => (
                        _ctx.onImageIconFileChange(
                          $event.target.name,
                          $event.target.files
                        )
                      ))
                            }, null, 40, _hoisted_47),
                            _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.Iconfilename), 1)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", null, [
                (_ctx.optionGenereateIconSelected != 'false')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                      _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.$t("ImageProcess.Chooseanicon")), 1),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_51, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ProgramFontAwesomeViewModel.FontAwesomeIcons, (FontAwesomeIcon) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: FontAwesomeIcon.Id
                            }, [
                              _createElementVNode("div", {
                                class: "col-xs-3 font-icon-area",
                                onClick: ($event: any) => (_ctx.ClickIconGenerator(FontAwesomeIcon))
                              }, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["divRoundCorner", {
                          divRoundCornerSelected:
                            _ctx.IconGeneratorId == FontAwesomeIcon.Id &&
                            _ctx.IconGeneratorId > 0,
                        }])
                                }, [
                                  _createElementVNode("i", {
                                    class: _normalizeClass(FontAwesomeIcon.IconName),
                                    style: _normalizeStyle(_ctx.GetFontAwesomeIconIconNameClass())
                                  }, null, 6)
                                ], 2)
                              ], 8, _hoisted_52)
                            ]))
                          }), 128))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_53, [
                        _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.$t("ImageProcess.PickyourIconColor")), 1),
                        _createElementVNode("div", _hoisted_55, [
                          _createElementVNode("a", {
                            class: "reset-color",
                            href: "javascript:void(0)",
                            onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.ResetColorToDefault()))
                          }, _toDisplayString(_ctx.$t("ImageProcess.ResetColorToDefault")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_56, [
                        _createElementVNode("div", _hoisted_57, [
                          _createElementVNode("div", _hoisted_58, [
                            (_ctx.ProgramFontAwesomeViewModel.SelectedForegroundColor)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                                  _createElementVNode("label", _hoisted_60, _toDisplayString(_ctx.$t("ImageProcess.Foreground")), 1),
                                  _createVNode(_component_colorpicker, {
                                    id: "Foregroundcolor",
                                    class: "colorpicker-width",
                                    color: _ctx.LatestForegroundcolor,
                                    onSetForegroundcolorFromPicker: _ctx.SetForegroundcolor
                                  }, null, 8, ["color", "onSetForegroundcolorFromPicker"]),
                                  _createElementVNode("div", {
                                    style: _normalizeStyle(_ctx.GetForegroundcolor()),
                                    class: "colorPicker"
                                  }, "    ", 4)
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.ProgramFontAwesomeViewModel.SelectedBackgroundColor)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                                  _createElementVNode("label", _hoisted_62, _toDisplayString(_ctx.$t("Background")), 1),
                                  _createVNode(_component_colorpicker, {
                                    id: "Backgroundcolor",
                                    class: "colorpicker-width",
                                    color: _ctx.LatestBackroundcolor,
                                    onSetBackgroundcolorFromPicker: _ctx.SetBackgroundcolor
                                  }, null, 8, ["color", "onSetBackgroundcolorFromPicker"]),
                                  _createElementVNode("div", {
                                    style: _normalizeStyle(_ctx.GetBackgroundcolor()),
                                    class: "colorPicker"
                                  }, "    ", 4)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_63, [
                            _createElementVNode("div", _hoisted_64, [
                              _createElementVNode("i", {
                                class: "fa fa-arrows-v exchangeIcon",
                                "aria-hidden": "true",
                                onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.SwapColors()))
                              })
                            ])
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  _createVNode(_component_vuecroppie, {
                    "img-src": _ctx.GetImageIconUrl(),
                    "typeof-image": _ctx.TypeofImageIconImage,
                    stencilprops: { aspectRatio: 12 / 12 },
                    "module-type": _ctx.moduleName,
                    "media-prefix": _ctx.mediaPrefix,
                    "is-full-image": _ctx.localIsFullImage,
                    onCroppedImageIconUrl: _ctx.CroppedImageIconUrl
                  }, null, 8, ["img-src", "typeof-image", "module-type", "media-prefix", "is-full-image", "onCroppedImageIconUrl"])
                ]),
                (_ctx.optionGenereateIconSelected == 'false')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                      (_ctx.errorText != '')
                        ? (_openBlock(), _createElementBlock("p", _hoisted_66, _toDisplayString(_ctx.$t(_ctx.errorText, {
                    size: _ctx.localIsFullImage ? _ctx.defaultCustomThemeLogoSize + " MB" : _ctx.maxSize + " MB",
                    givenWidth: _ctx.IconImageSizeWidth,
                    givenHeight: _ctx.IconImageSizeHeight,
                  })), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.showFeaturedImage && _ctx.selectedBatchImageRadiobtn !== _ctx.EditIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
              (!_ctx.localOnlyShowFeatureImage)
                ? (_openBlock(), _createElementBlock("div", _hoisted_68))
                : _createCommentVNode("", true),
              (!_ctx.localOnlyShowFeatureImage)
                ? (_openBlock(), _createElementBlock("div", _hoisted_69, _toDisplayString(_ctx.$t("ImageProcess.Selectfeaturedimage")), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_70, [
                  _createElementVNode("div", _hoisted_71, [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _mergeProps({ class: "imgdivDefault" }, _toHandlers(
                      _ctx.optionFeatureImagedefaultColorSelected == 'false'
                        ? { click: () => _ctx.$refs.file1.click() }
                        : {}
                    , true)), [
                        _createElementVNode("img", {
                          class: "imgclsFeatured",
                          src: _ctx.GetCroppedFeaturedImageIconUrl()
                        }, null, 8, _hoisted_72)
                      ], 16),
                      _createElementVNode("div", _hoisted_73, [
                        (
                        _ctx.IsFeaturedImageIconUrlExists() &&
                          _ctx.visibleDeleteLinkUnderFeaturedImage
                      )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
                              _createElementVNode("a", {
                                class: "delete",
                                href: "javascript:void(0)",
                                onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.clearFeaturedImageIconUrl()))
                              }, _toDisplayString(_ctx.$t("ImageProcess.delete")), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_75, _cache[29] || (_cache[29] = [
                              _createElementVNode("div", { href: "javascript:void(0)" }, "   ", -1)
                            ])))
                      ])
                    ])
                  ]),
                  (!_ctx.localOnlyShowFeatureImage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                        _createElementVNode("div", _hoisted_77, [
                          _createElementVNode("label", _hoisted_78, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.optionFeatureImagedefaultColorSelected) = $event)),
                              type: "radio",
                              name: "featureDefaultColor",
                              value: "true"
                            }, null, 512), [
                              [_vModelRadio, _ctx.optionFeatureImagedefaultColorSelected]
                            ]),
                            _createTextVNode("  " + _toDisplayString(_ctx.$t("ImageProcess.Defaulttomaincolor")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_79, [
                          _createElementVNode("label", _hoisted_80, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.optionFeatureImagedefaultColorSelected) = $event)),
                              type: "radio",
                              name: "featureimageUpload",
                              value: "false"
                            }, null, 512), [
                              [_vModelRadio, _ctx.optionFeatureImagedefaultColorSelected]
                            ]),
                            _createTextVNode("  " + _toDisplayString(_ctx.$t("ImageProcess.Uploadfromfile")), 1)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.localOnlyShowFeatureImage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                        (_ctx.localIsFullImage)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                              _createElementVNode("label", _hoisted_83, _toDisplayString(_ctx.$t("ImageProcess.SelectImageCustom")), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_84, [
                              _createElementVNode("label", _hoisted_85, _toDisplayString(_ctx.$t("ImageProcess.SelectImage")), 1)
                            ]))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  (_ctx.optionFeatureImagedefaultColorSelected == 'false')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_86, [
                        (!_ctx.localOnlyShowFeatureImage)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_87, _toDisplayString(_ctx.$t("ImageProcess.640pixelimagerecommended")), 1))
                          : _createCommentVNode("", true),
                        (_ctx.localOnlyShowFeatureImage && !_ctx.localIsFullImage)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_88, _toDisplayString(_ctx.$t("ImageProcess.640pixelimagerecommendedFeature")), 1))
                          : _createCommentVNode("", true),
                        (_ctx.localOnlyShowFeatureImage && _ctx.localIsFullImage && _ctx.moduleName === 'EventWebPage')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                              _createElementVNode("span", {
                                innerHTML: _ctx.$t('ImageProcess.BackgroundImageMessage',{backgroundImageWidthRatio: _ctx.backgroundImageWidthRatio, backgroundImageHeightRatio: _ctx.backgroundImageHeightRatio7, maxBackgroundImageSize: _ctx.maxBackgroundImageSize, backgroundImageWidth: _ctx.backgroundImageWidth, backgroundImageHeight:_ctx.backgroundImageHeight700})
                              }, null, 8, _hoisted_90)
                            ]))
                          : (_ctx.localOnlyShowFeatureImage && _ctx.localIsFullImage)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_91, [
                                _createElementVNode("span", {
                                  innerHTML: _ctx.$t('ImageProcess.BackgroundImageMessage',{backgroundImageWidthRatio: _ctx.backgroundImageWidthRatio, backgroundImageHeightRatio: _ctx.backgroundImageHeightRatio, maxBackgroundImageSize: _ctx.maxBackgroundImageSize, backgroundImageWidth: _ctx.backgroundImageWidth, backgroundImageHeight: _ctx.backgroundImageHeight})
                                }, null, 8, _hoisted_92)
                              ]))
                            : _createCommentVNode("", true),
                        (!_ctx.localOnlyShowFeatureImage)
                          ? (_openBlock(), _createElementBlock("label", {
                              key: 4,
                              hidden: !_ctx.isVisibleShowTitleCheckBox
                            }, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.localShowTitleBelowFeaturedImage) = $event)),
                                type: "checkbox"
                              }, null, 512), [
                                [_vModelCheckbox, _ctx.localShowTitleBelowFeaturedImage]
                              ]),
                              _createTextVNode(" " + _toDisplayString(_ctx.GetShowFeaturedImageText()), 1)
                            ], 8, _hoisted_93))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_94, [
                          _createElementVNode("label", _hoisted_95, [
                            _createElementVNode("input", {
                              id: "imageFileFeatureImage",
                              ref: "file1",
                              type: "file",
                              name: _ctx.imageFileFeatureImage,
                              onChange: _cache[22] || (_cache[22] = ($event: any) => (
                        _ctx.onFeaturedImageIconFileChange(
                          $event.target.name,
                          $event.target.files
                        )
                      ))
                            }, null, 40, _hoisted_96),
                            _createElementVNode("span", _hoisted_97, _toDisplayString(_ctx.FeaturedIconfilename), 1)
                          ])
                        ]),
                        (_ctx.ShowExternalInfoFeaturedImageMessage())
                          ? (_openBlock(), _createElementBlock("div", _hoisted_98, _toDisplayString(_ctx.$t("ImageProcess.FeaturedImageExternalInfoItems")), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.optionFeatureImagedefaultColorSelected == 'true')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_99, [
                        _createVNode(_component_featuredImage, {
                          "img-src": _ctx.GetFeaturedImageIconUrl(),
                          "image-width": _ctx.localFeaturedImageWidth,
                          "image-height": _ctx.localFeaturedImageHeight,
                          "left-postion": _ctx.leftPosition,
                          "top-position": _ctx.topPosition,
                          "module-name": _ctx.moduleName,
                          onCroppedFeaturedImageIconUrl: _ctx.CroppedFeaturedImageIconUrl
                        }, null, 8, ["img-src", "image-width", "image-height", "left-postion", "top-position", "module-name", "onCroppedFeaturedImageIconUrl"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_ctx.moduleName === 'Event')
                ? (_openBlock(), _createElementBlock("div", _hoisted_100, [
                    _createVNode(_component_vuecroppie, {
                      "img-src": _ctx.GetFeaturedImageIconUrl(),
                      "typeof-image": _ctx.TypeofImageIconFeatureImage,
                      stencilprops: { aspectRatio: 4 / 1 },
                      width: 640,
                      height: 160,
                      "media-prefix": _ctx.mediaPrefix,
                      onCroppedFeaturedImageIconUrl: _ctx.CroppedFeaturedImageIconUrl
                    }, null, 8, ["img-src", "typeof-image", "media-prefix", "onCroppedFeaturedImageIconUrl"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.localIsFullImage && _ctx.moduleName === 'EventWebPage')
                ? (_openBlock(), _createElementBlock("div", _hoisted_101, [
                    _createVNode(_component_vuecroppie, {
                      "img-src": _ctx.GetFeaturedImageIconUrl(),
                      "typeof-image": _ctx.TypeofImageIconFeatureImage,
                      stencilprops: { aspectRatio: 16 / 7 },
                      width: 1600,
                      height: 700,
                      "media-prefix": _ctx.mediaPrefix,
                      onCroppedFeaturedImageIconUrl: _ctx.CroppedFeaturedImageIconUrl
                    }, null, 8, ["img-src", "typeof-image", "media-prefix", "onCroppedFeaturedImageIconUrl"])
                  ]))
                : (_ctx.localIsFullImage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_102, [
                      _createVNode(_component_vuecroppie, {
                        "img-src": _ctx.GetFeaturedImageIconUrl(),
                        "typeof-image": _ctx.TypeofImageIconFeatureImage,
                        stencilprops: { aspectRatio: 16 / 9 },
                        width: 1600,
                        height: 900,
                        "media-prefix": _ctx.mediaPrefix,
                        onCroppedFeaturedImageIconUrl: _ctx.CroppedFeaturedImageIconUrl
                      }, null, 8, ["img-src", "typeof-image", "media-prefix", "onCroppedFeaturedImageIconUrl"])
                    ]))
                  : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_103, [
                (_ctx.featuredImageerrorText != '')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_104, _toDisplayString(_ctx.$t(_ctx.featuredImageerrorText, {
                  size: (_ctx.localIsFullImage)? '5' + ' MB': _ctx.featuredImageSize + " MB",
                  givenWidth: _ctx.featuredImageSizeWidth,
                  givenHeight: _ctx.featuredImageSizeHeight,
                })), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    (_ctx.moduleName === _ctx.EventModule || (_ctx.localIsFullImage && !_ctx.localShowFullImageOption))
      ? (_openBlock(), _createElementBlock("div", _hoisted_105, [
          _cache[30] || (_cache[30] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", _hoisted_106, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "btn btn-secondary btn-size-default btn-cancelevent cursor-pointer",
                disabled: _ctx.isDirtyObject,
                onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.Discard()))
              }, _toDisplayString(_ctx.$t("Button.Discard")), 9, _hoisted_107)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-size-default width100 btn-create-event cursor-pointer",
                disabled: _ctx.isSaveButtonDisabled || _ctx.isDirtyObject,
                onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.clickOnSave()))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.CaptionDone")), 1)
              ], 8, _hoisted_108)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 34))
}