import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "scroll-top grid-listing" }
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "right-container-nav" }
const _hoisted_4 = { class: "width25" }
const _hoisted_5 = { class: "width50" }
const _hoisted_6 = { class: "main-heading primary-heading" }
const _hoisted_7 = { class: "add-btn-position" }
const _hoisted_8 = { class: "padding-top-20" }
const _hoisted_9 = { class: "margin-top-30" }
const _hoisted_10 = {
  key: 0,
  class: "empty-msg-position"
}
const _hoisted_11 = { class: "text-msg-padding" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "margin-top-10" }
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 1,
  class: "pin-menu-icon"
}
const _hoisted_17 = {
  key: 2,
  class: "pin-menu-icon"
}
const _hoisted_18 = { class: "item-position-from-image valign-parent50 width70" }
const _hoisted_19 = { class: "valign" }
const _hoisted_20 = { class: "description-heading" }
const _hoisted_21 = {
  key: 0,
  class: "valign margin-left-10"
}
const _hoisted_22 = { class: "valign-parent55 width30 text-right" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseFolder()))
            }, _toDisplayString(_ctx.$t("Button.Close")), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("CustomCategoryFolder.AssignFolder")), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              class: "btn btn-primary btn-size-default",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SaveFolder()))
            }, _toDisplayString(_ctx.$t("Button.Save")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.visibleValidationSummary)
            ? (_openBlock(), _createBlock(_component_ValidationSummary, {
                key: 0,
                "error-fields": _ctx.validationErrorFields,
                onCloseClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CloseValidationSummary($event)))
              }, null, 8, ["error-fields"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("CustomCategoryFolder.SelectFolder")), 1),
        ((_ctx.localFolderItems==null || _ctx.localFolderItems.length==0))
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, [
                _createElementVNode("i", {
                  class: "font-size-12",
                  innerHTML: _ctx.$t('CustomCategoryFolder.EmptyScreenMessageLine1')
                }, null, 8, _hoisted_12)
              ]),
              _createElementVNode("p", null, [
                _createElementVNode("span", {
                  class: "font-size-12",
                  innerHTML: _ctx.$t('CustomCategoryFolder.EmptyScreenMessageLine2')
                }, null, 8, _hoisted_13)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localFolderItems, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.Id,
              class: "margin-top-15",
              style: {"display":"inline-flex","width":"100%"}
            }, [
              _createElementVNode("div", null, [
                (item.FontAwesomeIconId===0 && item.Id>0)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.GetImageUrl(item.IconUrl),
                      class: "iconImg"
                    }, null, 8, _hoisted_15))
                  : (item.FontAwesomeIconId===0 && item.Id===0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("i", {
                          class: _normalizeClass([item.FontAwesomeIconName, "pinned-menu-icon"])
                        }, null, 2)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("i", {
                          class: _normalizeClass([item.FontAwesomeIconName, "pinned-menu-icon"])
                        }, null, 2)
                      ]))
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("span", _hoisted_20, _toDisplayString(item.Name), 1),
                  (item.FontAwesomeIconId > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_21, _cache[3] || (_cache[3] = [
                        _createElementVNode("i", {
                          class: "fa fa-thumb-tack",
                          "aria-hidden": "true"
                        }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                (item.IsSelected)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-primary btn-size-default valign",
                      onClick: ($event: any) => (_ctx.removeItem(item))
                    }, _toDisplayString(_ctx.$t("Button.Selected")), 9, _hoisted_23))
                  : _createCommentVNode("", true),
                (!item.IsSelected)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn btn-secondary btn-size-default valign",
                      onClick: ($event: any) => (_ctx.addItem(item))
                    }, _toDisplayString(_ctx.$t("Button.Select")), 9, _hoisted_24))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}