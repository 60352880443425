import { createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modalpopup" }
const _hoisted_2 = {
  class: "modal",
  "data-backdrop": "static",
  "data-keyboard": "false"
}
const _hoisted_3 = { class: "modal-dialog" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = {
  id: "my-player",
  class: "video-js vjs-default-skin",
  width: "600",
  height: "600",
  controls: "",
  playsinline: ""
}
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_Transition, { name: "modal" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                type: "button",
                class: "close",
                "data-dismiss": "modal",
                "aria-label": "Close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.Cancel();})
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("span", { "aria-hidden": "true" }, "×", -1)
              ])),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("video", _hoisted_6, [
                    _createElementVNode("source", {
                      type: "video/mux",
                      src: _ctx.vodVideoDetail.PlayBackUrl
                    }, null, 8, _hoisted_7)
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}