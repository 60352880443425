import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "customCategoryLinkedProgramDiv" }
const _hoisted_2 = { class: "grid-listing" }
const _hoisted_3 = { class: "inline-flex100 justify-content-space-between align-items-start" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "section-heading" }
const _hoisted_6 = { class: "hyperLink inline-flex" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "add-information-text" }
const _hoisted_9 = {
  key: 0,
  class: "inline-flex100 notlength"
}
const _hoisted_10 = {
  key: 1,
  class: "ajax-loader"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "inline-flex100 margin-top-20" }
const _hoisted_13 = { class: "width55 inline-flex100" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "margin-left-10 valign-parent50" }
const _hoisted_16 = { class: "valign" }
const _hoisted_17 = { class: "font-weight-bold" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "add-information-div" }
const _hoisted_20 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.localHeaderText), 1)
        ]),
        (_ctx.localShowAddButton === true && _ctx.localLinkedProgramLoading === false)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "add-information-div action-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ShowSearch('')))
            }, [
              _createElementVNode("a", _hoisted_6, [
                _createElementVNode("img", {
                  src: _ctx.addIconImage,
                  alt: "Add icon"
                }, null, 8, _hoisted_7),
                _createElementVNode("span", _hoisted_8, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.localBtnText), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.localAllParticipants.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.localNoProfileText), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.localLinkedProgramLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("img", {
              class: "img-responsive ajax-loader-img",
              src: _ctx.loadingImage,
              alt: "Wait"
            }, null, 8, _hoisted_11)
          ]))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.localAllParticipants, (item) => {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
              key: item.Id
            }, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", null, [
                    (_ctx.IsIconUrlValid(item.IconUrl))
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: item.IconUrl,
                          class: "icon-Img round-image icon-img-custom"
                        }, null, 8, _hoisted_14))
                      : _createCommentVNode("", true),
                    (!_ctx.IsIconUrlValid(item.IconUrl))
                      ? (_openBlock(), _createBlock(_component_avatar, {
                          key: 1,
                          background: _ctx.GetDefaultBackgroundColor(),
                          color: _ctx.GetDefaultForegroundColor(),
                          size: 60,
                          lighten: 40,
                          border: false,
                          name: _ctx.GetIntials(item.FirstName, item.LastName),
                          class: "display-contents icon-img-custom"
                        }, null, 8, ["background", "color", "name"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("span", _hoisted_17, _toDisplayString(item.Name), 1),
                      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(item.Title) + " ", 1),
                        (item.Company !== null && item.Title !== null && item.Company !== '' && item.Title !== '')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, "-"))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(item.Company), 1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("button", {
                    class: "btn btn-secondary btn-size-default",
                    onClick: ($event: any) => (_ctx.RemoveItem(item))
                  }, _toDisplayString(_ctx.$t('Button.Remove')), 9, _hoisted_20)
                ])
              ])
            ])), [
              [_vShow, _ctx.localAllParticipants.length > 0]
            ])
          }), 128))
    ])
  ]))
}