import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "colorpicker",
  class: "input-group color-picker"
}
const _hoisted_2 = {
  id: "spnColorPicker",
  class: "input-group-addon color-picker-container color-picker-border"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chrome = _resolveComponent("Chrome")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.colorValue) = $event)),
      type: "text",
      class: "form-control",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.togglePicker())),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateFromInput && _ctx.updateFromInput(...args)))
    }, null, 544), [
      [_vModelText, _ctx.colorValue]
    ]),
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("span", {
        class: "current-color",
        style: _normalizeStyle('background-color: ' + _ctx.colorValue),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.togglePicker()))
      }, null, 4),
      (_ctx.displayPicker)
        ? (_openBlock(), _createBlock(_component_Chrome, {
            key: 0,
            modelValue: _ctx.colors,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.colors) = $event))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ])
  ])), [
    [_directive_click_outside, _ctx.closePicker]
  ])
}