import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "grid-listing" }
const _hoisted_2 = {
  key: 0,
  class: "margin-top-15"
}
const _hoisted_3 = { class: "edit-section" }
const _hoisted_4 = { class: "edit-folder" }
const _hoisted_5 = ["src", "title"]
const _hoisted_6 = {
  key: 1,
  class: "pin-menu-icon"
}
const _hoisted_7 = {
  id: "divCustomCategoryFolderEditName",
  class: "item-position-from-image valign-parent50 width100"
}
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = {
  key: 0,
  class: "error-vuelidate"
}
const _hoisted_10 = { class: "submit-position" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.localFolderItem && !_ctx.showImageProcess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                id: "divCustomCategoryFolderEditIcon",
                class: "img-select",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnImage(true)))
              }, [
                (_ctx.localFolderItem.FontAwesomeIconId===0)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      id: "imgCustomCategoryFolderEditIcon",
                      class: "iconImg round-image",
                      src: _ctx.addIcon,
                      title: _ctx.$t('AddEditImage')
                    }, null, 8, _hoisted_5))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("i", {
                        class: _normalizeClass([_ctx.localFolderItem.FontAwesomeIconName, "pinned-menu-icon"])
                      }, null, 2)
                    ]))
              ]),
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("input", {
                  id: "inputCustomCategoryFolderEditName",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.localFolderItem.Name.$model) = $event)),
                  type: "text",
                  class: _normalizeClass(["form-control valign width100", _ctx.status(_ctx.v$.localFolderItem.Name)]),
                  placeholder: _ctx.GetPlaceholder(),
                  maxlength: "70"
                }, null, 10, _hoisted_8), [
                  [
                    _vModelText,
                    _ctx.v$.localFolderItem.Name.$model,
                    void 0,
                    { trim: true }
                  ]
                ]),
                (_ctx.v$.localFolderItem.Name.$error)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t("Validation.NameRequired")), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  id: "btnPinCustomCategoryFolder",
                  class: "btn btn-primary btn-size-default",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.PinToMenu()))
                }, [
                  (_ctx.localFolderItem.PinnedToMenu)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("Unpin")), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t("Pintomenu")), 1))
                ])
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  id: "btnSaveCustomCategoryFolder",
                  class: "btn btn-primary btn-size-default submit-btn",
                  disabled: _ctx.isButtonDisabled,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Submit(_ctx.v$)))
                }, _toDisplayString(_ctx.$t("SaveButton")), 9, _hoisted_13),
                _createElementVNode("button", {
                  id: "btnCancelCustomCategoryFolder",
                  class: "btn btn-secondary btn-size-default margin-left-15",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Cancel(_ctx.v$)))
                }, _toDisplayString(_ctx.$t("CancelButton")), 1)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}