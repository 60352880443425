import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "grid-listing" }
const _hoisted_2 = {
  key: 1,
  class: "edit-section"
}
const _hoisted_3 = { id: "divCustomCategoryFolderIcon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  id: "divCustomCategoryPinFolderIcon",
  class: "pin-menu-icon"
}
const _hoisted_6 = {
  id: "spnCustomCategoryFolderName",
  class: "valign wrap-span"
}
const _hoisted_7 = {
  key: 0,
  class: "valign margin-left-10"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 2,
  id: "customCategoryFolderItemEditPane",
  class: "margin-top-15"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_CustomCategoryFolderEditItemPane = _resolveComponent("CustomCategoryFolderEditItemPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('DeleteFolder'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.localFolderItem && !_ctx.showEditFolder)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.localFolderItem.FontAwesomeIconId===0)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  id: "imgCustomCategoryFolderIcon",
                  src: _ctx.GetImageUrl(_ctx.localFolderItem.IconUrl),
                  class: "iconImg"
                }, null, 8, _hoisted_4))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("i", {
                    class: _normalizeClass([_ctx.localFolderItem.FontAwesomeIconName, "pinned-menu-icon"])
                  }, null, 2)
                ]))
          ]),
          _createElementVNode("div", {
            id: "divCustomCategoryFolderName",
            class: "item-position-from-image valign-parent50 display-flex align-items-center",
            style: _normalizeStyle(_ctx.getLeftDivStyle(_ctx.isMobileView))
          }, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.localFolderItem.Name), 1),
            (_ctx.localFolderItem.PinnedToMenu)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _cache[8] || (_cache[8] = [
                  _createElementVNode("i", {
                    class: "fa fa-thumb-tack",
                    "aria-hidden": "true"
                  }, null, -1)
                ])))
              : _createCommentVNode("", true)
          ], 4),
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.getRightDivStyle(_ctx.isMobileView)),
            class: "right-container-btn"
          }, [
            _createElementVNode("button", {
              id: "btnEditCustomCategoryFolder",
              class: _normalizeClass(["btn btn-secondary valign", [_ctx.isMobileView ==true ? '' : 'btn-size-default']]),
              style: _normalizeStyle(_ctx.getEditButtonstyle(_ctx.isMobileView)),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectItem(_ctx.localFolderItem)))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Edit")), 1)
            ], 6),
            _createElementVNode("div", {
              class: "btn btn-delete valign",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.DeleteSelectedFolder(_ctx.localFolderItem)))
            }, [
              _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_8)
            ])
          ], 4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showEditFolder)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_CustomCategoryFolderEditItemPane, {
            "selected-folder-item": _ctx.localFolderItem,
            "applicationborder-color": _ctx.loaderBorderColor,
            onCustomCategoryFolderItemEditImageOperation: _cache[3] || (_cache[3] = ($event: any) => (_ctx.CustomCategoryFolderItemImageEditedOperation($event))),
            onCustomCategoryFolderItemCancelOperation: _cache[4] || (_cache[4] = ($event: any) => (_ctx.CustomCategoryFolderItemCancelOperation($event))),
            onCustomCategoryFolderItemEditOperation: _cache[5] || (_cache[5] = ($event: any) => (_ctx.CustomCategoryFolderItemEditedOperation($event))),
            onCustomCategoryFolderItemImageUploadOperation: _cache[6] || (_cache[6] = ($event: any) => (_ctx.CustomCategoryFolderItemImageUploadOperation($event))),
            onFolderInformationFonticon: _cache[7] || (_cache[7] = ($event: any) => (_ctx.FolderItemFontUploadOperation($event)))
          }, null, 8, ["selected-folder-item", "applicationborder-color"])
        ]))
      : _createCommentVNode("", true)
  ]))
}