import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "width100" }
const _hoisted_2 = { class: "main-heading primary-heading" }
const _hoisted_3 = { class: "text-right" }
const _hoisted_4 = { class: "file-upload-container" }
const _hoisted_5 = { class: "imageSection" }
const _hoisted_6 = { class: "imgdivDefault" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "delete-link-container" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "fileUploadDiv file-upload-control" }
const _hoisted_13 = { class: "file-upload-margin" }
const _hoisted_14 = { class: "file" }
const _hoisted_15 = ["name"]
const _hoisted_16 = { class: "file-custom border-radius-10" }
const _hoisted_17 = {
  key: 0,
  class: "allow-extension-msg"
}
const _hoisted_18 = {
  key: 0,
  class: "image-croppie-margin"
}
const _hoisted_19 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vuecroppie = _resolveComponent("vuecroppie")!

  return (_openBlock(), _createElementBlock("div", {
    id: "dvFileupload",
    class: _normalizeClass(["flex-container", {'scroll-top' :!_ctx.isMobileView}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["inline-flex100 align-items-center", {'minHeight45': _ctx.isMobileView,'minHeight50':!_ctx.isMobileView}])
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "btn btn-secondary btn-size-default",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnBackParentEdit()))
        }, _toDisplayString(_ctx.$t("ImageProcess.back")), 1)
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headingTitle), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-size-default",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickOnBackParentEdit()))
        }, _toDisplayString(_ctx.$t("ImageProcess.save")), 1)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.localModuleType === 'Notification')
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "imgclsNotification",
                src: _ctx.GetImageIconUrl(),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.file.click()))
              }, null, 8, _hoisted_7))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: "imgcls",
                src: _ctx.GetCroppedImageIconUrl(),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$refs.file.click()))
              }, null, 8, _hoisted_8))
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.IsImageIconUrlExists())
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("a", {
                  class: "delete",
                  href: "javascript:void(0)",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearImageIconUrl()))
                }, _toDisplayString(_ctx.$t("ImageProcess.delete")), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[6] || (_cache[6] = [
                _createElementVNode("div", { href: "javascript:void(0)" }, "   ", -1)
              ])))
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", _hoisted_14, [
            _createElementVNode("input", {
              id: "uploadImage",
              ref: "file",
              type: "file",
              name: _ctx.uploadImage,
              onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onImageIconFileChange($event.target.name, $event.target.files)))
            }, null, 40, _hoisted_15),
            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.Iconfilename), 1)
          ]),
          (_ctx.fileExtensions!='')
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t("AllowedExtension")) + " : " + _toDisplayString(_ctx.fileExtensions), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      (_ctx.showImageCropping)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createVNode(_component_vuecroppie, {
              "img-src": _ctx.GetImageIconUrl(),
              "typeof-image": _ctx.TypeofImageIconImage,
              stencilprops: {aspectRatio: 12/12},
              "module-type": _ctx.localModuleType,
              onCroppedImageIconUrl: _ctx.CroppedImageIconUrl
            }, null, 8, ["img-src", "typeof-image", "module-type", "onCroppedImageIconUrl"])
          ]))
        : _createCommentVNode("", true),
      _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", null, [
        (_ctx.errorText!='')
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "errorText",
              innerHTML: _ctx.$t(_ctx.errorText,{size: _ctx.maxSize+ ' MB',givenWidth: _ctx.iconImageSizeWidth, givenHeight: _ctx.iconImageSizeHeight})
            }, null, 8, _hoisted_19))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}