import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "descIcon"
}
const _hoisted_2 = {
  key: 1,
  class: "ascIcon"
}
const _hoisted_3 = {
  key: 0,
  class: "margin-top-20"
}
const _hoisted_4 = { class: "display-flex" }
const _hoisted_5 = { class: "divCroppie width100" }
const _hoisted_6 = { class: "picturecontainer picture-container-area" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_draggable_resizable = _resolveComponent("vue-draggable-resizable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "cropAndScale",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ShowDivImageScaleandCrop()))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t("ImageCroppie.CalibrateImage")) + " ", 1),
      _createElementVNode("div", null, [
        (_ctx.showImageScaleandCrop)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[3] || (_cache[3] = [
              _createElementVNode("i", {
                class: "fa fa-sort-desc",
                "aria-hidden": "true"
              }, null, -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[4] || (_cache[4] = [
              _createElementVNode("i", {
                class: "fa fa-sort-asc",
                "aria-hidden": "true"
              }, null, -1)
            ])))
      ])
    ]),
    (_ctx.showImageScaleandCrop)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.imgSrc!=_ctx.loadingImage)
                  ? (_openBlock(), _createBlock(_component_vue_draggable_resizable, {
                      key: 0,
                      style: _normalizeStyle(_ctx.getXY()),
                      onDragging: _ctx.onDrag,
                      onDragstop: _ctx.onDragstop,
                      onChange: _ctx.onDragstop
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          id: "headerimage",
                          style: _normalizeStyle(_ctx.getImageDimensions()),
                          src: _ctx.GetImgeUrl(),
                          class: "headerimage ui-corner-all position-absolute",
                          onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getImagePositions())),
                          onTouchstart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getImagePositions()))
                        }, null, 44, _hoisted_7)
                      ]),
                      _: 1
                    }, 8, ["style", "onDragging", "onDragstop", "onChange"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("img", {
                        id: "headerimage",
                        src: _ctx.loadingImage,
                        class: "headerimage ui-corner-all loader-position"
                      }, null, 8, _hoisted_9)
                    ]))
              ])
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}