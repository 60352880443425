import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "top-margin-in-fields"
}
const _hoisted_2 = {
  id: "dvMeetAppLiveThresholdLimitReachedDetails",
  class: "alert alert-warning thresh-holdLimit-warning inline-flex100",
  role: "alert"
}
const _hoisted_3 = { class: "threshold-message message-content" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "ajax-loader grid-listing"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "no-margin prerecordingheading bold-text display-flex" }
const _hoisted_12 = {
  key: 0,
  class: "top-margin-in-fields prerecordingheading"
}
const _hoisted_13 = { class: "top-margin-in-fields italic-text" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "top-margin-in-fields" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = {
  key: 1,
  class: "top-margin-in-fields display-flex flex-direction-column"
}
const _hoisted_18 = {
  key: 0,
  class: "description no-margin"
}
const _hoisted_19 = {
  key: 1,
  class: "inline-flex100 row margin-bottom-15px"
}
const _hoisted_20 = { class: "col-xl-12 video-details-div" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col-xl-4 col-xs-12 video-player" }
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 0,
  class: "overlay border-radius-10"
}
const _hoisted_25 = { class: "video-duration" }
const _hoisted_26 = {
  key: 0,
  class: "col-xl-6 col-xs-10 video-details"
}
const _hoisted_27 = { class: "table table-borderless" }
const _hoisted_28 = {
  class: "field",
  colspan: "8"
}
const _hoisted_29 = { class: "value" }
const _hoisted_30 = {
  class: "field",
  colspan: "8"
}
const _hoisted_31 = { class: "value" }
const _hoisted_32 = {
  class: "field",
  colspan: "8"
}
const _hoisted_33 = { class: "value" }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = {
  class: "field",
  colspan: "8"
}
const _hoisted_36 = { class: "value" }
const _hoisted_37 = {
  key: 1,
  class: "col-xl-6 col-xs-10 error-message"
}
const _hoisted_38 = {
  key: 2,
  class: "manage-div-width manage-button display-flex justify-content-end col-xl-2 col-xs-2"
}
const _hoisted_39 = { class: "dropdown inline-flex visible-manage-button valign" }
const _hoisted_40 = ["src"]
const _hoisted_41 = { class: "dropdown-menu" }
const _hoisted_42 = { class: "cursor-pointer" }
const _hoisted_43 = { class: "cursor-pointer" }
const _hoisted_44 = { class: "information-message" }
const _hoisted_45 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.vonageThresholdLimitReached)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[11] || (_cache[11] = _createElementVNode("div", null, [
              _createElementVNode("span", { class: "font-icon" }, [
                _createElementVNode("i", {
                  class: "fa fa-exclamation-circle",
                  "aria-hidden": "true"
                })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.GetThresholdLimitReachedMsg()), 1)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.showLoader())
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("img", {
                  class: "img-responsive ajax-loader-img",
                  src: _ctx.loadingImage,
                  alt: "Wait"
                }, null, 8, _hoisted_6)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.modalPopupVisible)
                  ? (_openBlock(), _createBlock(_component_ModalPopup, {
                      key: 0,
                      "show-modal-popup": _ctx.modalPopupVisible,
                      "header-content": _ctx.modalPopupHeader,
                      "body-heading": _ctx.modalPopupHeading,
                      "body-content": _ctx.modalPopupContent,
                      "show-close-button": _ctx.showCloseButton,
                      "show-cancel-button": _ctx.modalPopupShowCancelButton,
                      "cancel-button-text": _ctx.modalPopupCancelButtonText,
                      "confirm-button-text": _ctx.modalPopupConfirmButtonText,
                      onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
                    }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button", "show-cancel-button", "cancel-button-text", "confirm-button-text"]))
                  : _createCommentVNode("", true),
                (_ctx.videoPlayerVisible)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_VideoPlayer, {
                        "vod-video-detail": 
              _ctx.localSelectedVodBaseClass.VodDetail.VodVideoDetail
            ,
                        "entity-title": _ctx.localSelectedVodBaseClass.Title,
                        onCloseVideoPlayer: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ClosePlayer($event)))
                      }, null, 8, ["vod-video-detail", "entity-title"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass([[
            {
              'top-margin-in-fields':
                _ctx.localSelectedVodBaseClass.SubModuleType !== 4 &&
                _ctx.localSelectedVodBaseClass.SubModuleType !== 3,
            },
            {
              borderTop1px:
                _ctx.localSelectedVodBaseClass.SubModuleType === 4 ||
                _ctx.localSelectedVodBaseClass.SubModuleType === 3,
            },
          ], "pre-recorded-section parent-information-icon-div border-radius-10"])
                }, [
                  (!_ctx.isVideoDetailsVisible)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", null, [
                          _withDirectives(_createElementVNode("div", { class: "information-icon-div" }, _toDisplayString(_ctx.$t("VirtualMeetings.PreRecordedDetails.InformationText")), 513), [
                            [_vShow, _ctx.isActive]
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("label", _hoisted_11, [
                              _createTextVNode(_toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.PreRecordedVODHeading", {
                    entityType: _ctx.localSelectedVodBaseClass.Title.toLowerCase(),
                  })) + " ", 1),
                              _createElementVNode("i", {
                                class: "fa fa-info-circle information-icon margin-left-5",
                                "aria-hidden": "true",
                                onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getInformationString(true))),
                                onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getInformationString(false)))
                              }, null, 32)
                            ])
                          ]),
                          (_ctx.isAddVideoWarningVisible)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                _cache[12] || (_cache[12] = _createElementVNode("i", { class: "fa fa-exclamation-triangle margin-right-3" }, null, -1)),
                                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.AddVideoWarningCommon", {
                    entityType: _ctx.localSelectedVodBaseClass.Title,
                  })), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (!_ctx.isAddVideoWarningVisible)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                _createElementVNode("div", _hoisted_15, [
                                  _createElementVNode("button", {
                                    class: _normalizeClass([{
                      'btn-disabled': _ctx.localSelectedVodBaseClass.EntityId == 0 || _ctx.isDisable === true,
                    }, "btn btn-primary btn-size-default"]),
                                    disabled: _ctx.localSelectedVodBaseClass.EntityId == 0 || _ctx.isDisable === true,
                                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.ShowAddVideo()))
                                  }, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.PreRecorded.AddVideo")), 11, _hoisted_16)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        (
                _ctx.localSelectedVodBaseClass.SubModuleType === 4 ||
                  _ctx.localSelectedVodBaseClass.SubModuleType === 3
              )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.RecordedSession")), 1))
                          : _createCommentVNode("", true),
                        (_ctx.localSelectedVodBaseClass.VodDetail !== null)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                              _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("div", _hoisted_21, [
                                  _createElementVNode("div", _hoisted_22, [
                                    _createElementVNode("div", {
                                      class: "container",
                                      onClick: _cache[6] || (_cache[6] = ($event: any) => (!_ctx.isVideoToBePlayed ? _ctx.PlayVideo() : false))
                                    }, [
                                      _createElementVNode("img", {
                                        class: "poster-image border-radius-10",
                                        src: _ctx.GetPosterUrl()
                                      }, null, 8, _hoisted_23),
                                      (
                          _ctx.latestVideoStatus == 'VideoAssetReady' ||
                            _ctx.latestVideoStatus == 'VideoAssetLiveStreamCompleted'
                        )
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                            _createElementVNode("a", {
                                              href: "javascript:void(0)",
                                              class: "icon",
                                              title: "Play video",
                                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.PlayVideo()))
                                            }, _cache[13] || (_cache[13] = [
                                              _createElementVNode("i", { class: "fa fa-play-circle" }, null, -1)
                                            ]))
                                          ]))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.videoDuration), 1)
                                    ])
                                  ]),
                                  (_ctx.latestVideoStatus !== 'VideoAssetErrored')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                        _createElementVNode("table", _hoisted_27, [
                                          _createElementVNode("tbody", null, [
                                            _createElementVNode("tr", null, [
                                              _cache[15] || (_cache[15] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                              _createElementVNode("td", _hoisted_28, [
                                                _createTextVNode(_toDisplayString(_ctx.$t("VirtualMeetings.PreRecordedDetails.Views")), 1),
                                                _cache[14] || (_cache[14] = _createElementVNode("span", { class: "color-red" }, " * ", -1))
                                              ]),
                                              _createElementVNode("td", _hoisted_29, [
                                                _createTextVNode(_toDisplayString(_ctx.localViewCount) + " ", 1),
                                                (
                                _ctx.localViewCount > 0 &&
                                  _ctx.localSelectedVodBaseClass.SubModuleType !== 4 &&
                                  _ctx.localSelectedVodBaseClass.SubModuleType !== 3
                              )
                                                  ? (_openBlock(), _createElementBlock("a", {
                                                      key: 0,
                                                      class: "hyper-link description",
                                                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.seeDetailedAnalytics()))
                                                    }, _toDisplayString(_ctx.$t("VirtualMeetings.Button.SeeDetails")), 1))
                                                  : _createCommentVNode("", true)
                                              ])
                                            ]),
                                            _createElementVNode("tr", null, [
                                              _cache[16] || (_cache[16] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                              _createElementVNode("td", _hoisted_30, _toDisplayString(_ctx.UploadRecordedOnLabel), 1),
                                              _createElementVNode("td", _hoisted_31, _toDisplayString(_ctx.GetUploadedOn()), 1)
                                            ]),
                                            _createElementVNode("tr", null, [
                                              _cache[17] || (_cache[17] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                              _createElementVNode("td", _hoisted_32, _toDisplayString(_ctx.$t(
                                "VirtualMeetings.PreRecordedDetails.Availabletill"
                              )), 1),
                                              _createElementVNode("td", _hoisted_33, _toDisplayString(_ctx.GetAvailableTillDate()), 1)
                                            ]),
                                            (
                            _ctx.localSelectedVodBaseClass.SubModuleType !== 4 &&
                              _ctx.localSelectedVodBaseClass.SubModuleType !== 3
                          )
                                              ? (_openBlock(), _createElementBlock("tr", _hoisted_34, [
                                                  _cache[18] || (_cache[18] = _createElementVNode("th", { scope: "row" }, null, -1)),
                                                  _createElementVNode("td", _hoisted_35, _toDisplayString(_ctx.$t(
                                "VirtualMeetings.PreRecordedDetails.Publishedon"
                              )), 1),
                                                  _createElementVNode("td", _hoisted_36, _toDisplayString(_ctx.formatPublishedOnDate(_ctx.publishDateE)), 1)
                                                ]))
                                              : _createCommentVNode("", true)
                                          ])
                                        ])
                                      ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.ErrorDetails")) + " :  ", 1),
                                        _createElementVNode("span", null, _toDisplayString(_ctx.localSelectedVodBaseClass.VodDetail.ErrorDetail), 1),
                                        _createElementVNode("a", {
                                          class: "hyper-link description",
                                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.TryAgain()))
                                        }, _toDisplayString(_ctx.$t("VirtualMeetings.AddVideo.TryAgain")), 1)
                                      ])),
                                  (
                      _ctx.latestVideoStatus !== 'VideoAssetErrored' &&
                        _ctx.latestVideoStatus !== 'VideoAssetDeleted'
                    )
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                        _createElementVNode("div", _hoisted_39, [
                                          _createElementVNode("div", {
                                            "data-toggle": "dropdown",
                                            class: _normalizeClass({ 'btn-disabled-dropdown': !_ctx.isCurrentUserMe })
                                          }, [
                                            _createElementVNode("img", {
                                              src: _ctx.manageIcon,
                                              class: "manage-icon cursor-pointer"
                                            }, null, 8, _hoisted_40)
                                          ], 2),
                                          _createElementVNode("ul", _hoisted_41, [
                                            _createElementVNode("li", {
                                              class: "manage-list-item",
                                              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.ShowAddVideo()))
                                            }, [
                                              _createElementVNode("a", _hoisted_42, _toDisplayString(_ctx.$t("Button.Edit")), 1)
                                            ]),
                                            (
                            _ctx.latestVideoStatus === 'VideoAssetReady' ||
                              _ctx.latestVideoStatus === 'VideoAssetLiveStreamCompleted'
                          )
                                              ? (_openBlock(), _createElementBlock("li", {
                                                  key: 0,
                                                  class: "manage-list-item",
                                                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.DeleteVideoClick()))
                                                }, [
                                                  _createElementVNode("a", _hoisted_43, _toDisplayString(_ctx.$t("Button.Delete")), 1)
                                                ]))
                                              : _createCommentVNode("", true)
                                          ])
                                        ])
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_44, [
                          _cache[21] || (_cache[21] = _createElementVNode("span", { class: "color-red" }, " *", -1)),
                          _cache[22] || (_cache[22] = _createTextVNode()),
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("VirtualMeetings.DisplayRecordingInformaion")), 1),
                          _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
                          (
                  _ctx.localSelectedVodBaseClass.SubModuleType === 4 ||
                    _ctx.localSelectedVodBaseClass.SubModuleType === 3
                )
                            ? (_openBlock(), _createElementBlock("span", _hoisted_45, [
                                _cache[19] || (_cache[19] = _createElementVNode("span", { class: "color-red" }, " #", -1)),
                                _cache[20] || (_cache[20] = _createTextVNode()),
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t("VirtualMeetings.DisplayAttendeesCountInformaion")), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                ], 2)
              ]))
        ]))
  ]))
}