import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "modalpopup" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  id: "onboardingDialogue",
  class: "modal"
}
const _hoisted_4 = { class: "modal-dialog model-dialogue-margin" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = { class: "modal-header" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "modal-body modal-body-padding" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "font-weight-bold" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 0,
  class: "alert alert-warning exceed-participant-div inline-flex100",
  role: "alert"
}
const _hoisted_17 = { class: "exceeded-message message-content" }
const _hoisted_18 = { class: "row margin-top-10" }
const _hoisted_19 = { class: "col-lg-4" }
const _hoisted_20 = { class: "col-lg-4" }
const _hoisted_21 = { class: "col-lg-3" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.localShowModalPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.pricingCalculatorType.arrPricingCalculatorTypes[_ctx.pricingPopupType].Heading), 1),
                  _createElementVNode("button", {
                    type: "button",
                    class: "close",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.Cancel();})
                  }, [
                    _createElementVNode("img", { src: _ctx.crossIcon }, null, 8, _hoisted_7)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("p", {
                    innerHTML: _ctx.pricingCalculatorType.arrPricingCalculatorTypes[_ctx.pricingPopupType].Line1
                  }, null, 8, _hoisted_9),
                  _createElementVNode("p", {
                    class: "top-margin-in-fields",
                    innerHTML: _ctx.pricingCalculatorType.arrPricingCalculatorTypes[_ctx.pricingPopupType].Line2
                  }, null, 8, _hoisted_10),
                  _createElementVNode("p", {
                    class: "top-margin-in-fields",
                    innerHTML: _ctx.virtualMeetingPricingCurrentPrice
                  }, null, 8, _hoisted_11),
                  (_ctx.showInputFields)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.pricingCalculatorType.arrPricingCalculatorTypes[_ctx.pricingPopupType].Line4), 1),
                        _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", { innerHTML: _ctx.virtualMeetingPricingExampleLine1 }, null, 8, _hoisted_14),
                        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", { innerHTML: _ctx.virtualMeetingPricingExampleLine2 }, null, 8, _hoisted_15),
                        (_ctx.isWarningVisible)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              _cache[7] || (_cache[7] = _createElementVNode("div", null, [
                                _createElementVNode("span", { class: "font-icon" }, [
                                  _createElementVNode("i", {
                                    class: "fa fa-exclamation-circle",
                                    "aria-hidden": "true"
                                  })
                                ])
                              ], -1)),
                              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.pricingCalculatorType.arrPricingCalculatorTypes[_ctx.pricingPopupType].Line7), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.Participants")), 1),
                            _createElementVNode("div", null, [
                              _withDirectives(_createElementVNode("input", {
                                id: "txtParticipants",
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.numberofParticipants) = $event)),
                                type: "number",
                                oninput: "validity.valid||(value='');",
                                class: "form-control",
                                min: "0",
                                value: "0",
                                onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.GetTotalPrice()))
                              }, null, 544), [
                                [_vModelText, _ctx.numberofParticipants]
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.Minutes")), 1),
                            _createElementVNode("div", null, [
                              _withDirectives(_createElementVNode("input", {
                                id: "txtMinutes",
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.numberofMintutes) = $event)),
                                type: "number",
                                oninput: "validity.valid||(value='');",
                                class: "form-control",
                                min: "0",
                                value: "0",
                                onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.GetTotalPrice()))
                              }, null, 544), [
                                [_vModelText, _ctx.numberofMintutes]
                              ])
                            ])
                          ]),
                          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-lg-1" }, [
                            _createElementVNode("div", null, [
                              _createElementVNode("label", { style: {"margin-top":"35px"} }, "=")
                            ])
                          ], -1)),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.Currency")), 1),
                            _createElementVNode("div", null, [
                              _withDirectives(_createElementVNode("input", {
                                id: "txtAmount",
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.totalAmount) = $event)),
                                type: "text",
                                class: "form-control",
                                readonly: "readonly"
                              }, null, 512), [
                                [_vModelText, _ctx.totalAmount]
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("p", {
                          class: "top-margin-in-fields margin-bottom-0",
                          innerHTML: _ctx.pricingCalculatorType.arrPricingCalculatorTypes[_ctx.pricingPopupType].Line8
                        }, null, 8, _hoisted_22)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-primary btn-secondary btn-size-default",
                    "data-dismiss": "modal",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.Cancel()))
                  }, _toDisplayString(_ctx.$t('Button.Close')), 1)
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}