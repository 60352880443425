import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "batch-operation" }
const _hoisted_2 = {
  key: 1,
  class: "right-container-margin"
}
const _hoisted_3 = {
  key: 2,
  class: "right-container-margin"
}
const _hoisted_4 = {
  key: 3,
  class: "right-container-margin"
}
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { class: "main-heading-parent" }
const _hoisted_7 = { class: "bulk-action-text-margin" }
const _hoisted_8 = { class: "batch-operation-main-content" }
const _hoisted_9 = { class: "action-middle-div" }
const _hoisted_10 = ["index"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "img-select iconSize" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "selectInformationStyle valign-parent60 item-position-from-image" }
const _hoisted_15 = { class: "valign batch-content-width" }
const _hoisted_16 = ["id"]
const _hoisted_17 = {
  key: 0,
  class: "action-middle-width"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_CustomCategoryAssignFolders = _resolveComponent("CustomCategoryAssignFolders")!
  const _component_AddProgramWithEntity = _resolveComponent("AddProgramWithEntity")!
  const _component_ImageProcess = _resolveComponent("ImageProcess")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('DeleteItems'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.showAssignToFolderBatchPane)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CustomCategoryAssignFolders, {
            "folder-items": _ctx.folderItems,
            "selected-items": _ctx.selectedItems,
            "selected-bulk-operation-model": _ctx.localSelectedBulkOperationModel,
            onSelectBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ShowAssignToFolderBatchPane(false)))
          }, null, 8, ["folder-items", "selected-items", "selected-bulk-operation-model"])
        ]))
      : (_ctx.showAddProgramnBatchPane)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_AddProgramWithEntity, {
              "all-programs": _ctx.localAllPrograms,
              "selected-items": _ctx.selectedItemsIds,
              "selected-bulk-operation-model": _ctx.localSelectedBulkOperationModel,
              "is-bulk-operation": true,
              "entity-type": _ctx.entityType,
              onSelectBack: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAttachProgram()))
            }, null, 8, ["all-programs", "selected-items", "selected-bulk-operation-model", "entity-type"])
          ]))
        : (_ctx.showCustomCategoryBatchImagesPane)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_ImageProcess, {
                "featured-image-full-src": _ctx.featureMissingImage,
                "featuredimage-src": _ctx.featureMissingImage,
                "heading-title": _ctx.isEnableFeaturedImage== true ? _ctx.$t('ImageProcess.BatchHeadingTitle') : _ctx.$t('ImageProcess.BatchHeadingIconOnlyTitle'),
                "image-src": _ctx.missingImage,
                "image-full-src": _ctx.missingImage,
                "module-name": _ctx.CustomCategoryModule,
                "selected-items": _ctx.selectedItems,
                "is-bulk-operation": true,
                "selected-bulk-operation-model": _ctx.localSelectedBulkOperationModel,
                "show-featured-image": _ctx.isEnableFeaturedImage,
                onSelectBack: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ShowBatchImagesPane(false))),
                onUpdateBulkImages: _cache[4] || (_cache[4] = ($event: any) => (_ctx.UpdateBulkImages($event)))
              }, null, 8, ["featured-image-full-src", "featuredimage-src", "heading-title", "image-src", "image-full-src", "module-name", "selected-items", "selected-bulk-operation-model", "show-featured-image"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t("Program.Batch.Header1")) + " " + _toDisplayString(_ctx.selectedItems.length) + " " + _toDisplayString(_ctx.$t("Program.Batch.Header2")), 1)
              ]),
              _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("Bulk.SelectAction")), 1),
              _createElementVNode("div", _hoisted_8, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "action-side-width" }, null, -1)),
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bulkEditOperations.arrBulkOperations, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.Id,
                      index: index,
                      class: _normalizeClass(["inner-content", _ctx.evenIndex(index)==true?'action-content-width-left':'action-content-width-right'])
                    }, [
                      _createElementVNode("div", {
                        class: "inner-content",
                        onClick: ($event: any) => (_ctx.HandleFunctionCall(item.objModel,item.ConfirmationMessage,item.ShowConfirmationPopup))
                      }, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("img", {
                            src: item.IconUrl,
                            style: _normalizeStyle(item.IconPadding)
                          }, null, 12, _hoisted_13)
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("label", {
                              id: 'lblCustomCategoryBatchEditOperations'+index,
                              class: "hyperLink questionLinkFont"
                            }, _toDisplayString(item.Name), 9, _hoisted_16),
                            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("span", null, _toDisplayString(item.Description), 1)
                          ])
                        ])
                      ], 8, _hoisted_11),
                      (_ctx.evenIndex(index))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17))
                        : _createCommentVNode("", true)
                    ], 10, _hoisted_10))
                  }), 128))
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "action-side-width" }, null, -1))
              ])
            ]))
  ]))
}