import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "dvMultimediaListing",
  class: "grid-listing"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "inline-flex100 justify-content-space-between align-items-center" }
const _hoisted_4 = { class: "width55" }
const _hoisted_5 = { class: "section-heading" }
const _hoisted_6 = {
  key: 0,
  class: "margin-right-7 action-button"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "add-link" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 0,
  class: "ajax-loader"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 0,
  class: "margin-top-10"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 1,
  class: "description"
}
const _hoisted_18 = {
  key: 1,
  class: "margin-top-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCategoryMultimediaListAndEditItemView = _resolveComponent("CustomCategoryMultimediaListAndEditItemView")!
  const _component_CustomCategoryMultimediaEditItemPane = _resolveComponent("CustomCategoryMultimediaEditItemPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.showFileUploadProcess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t("Multimedia")), 1)
            ]),
            (!_ctx.localMultimediaLoading && _ctx.selectedCustomCategoryId>0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("a", {
                    class: "hyperLink inline-flex",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.AddMultimediaItem()))
                  }, [
                    _createElementVNode("img", {
                      src: _ctx.addIconImage,
                      alt: "Add icon"
                    }, null, 8, _hoisted_7),
                    _createElementVNode("span", _hoisted_8, [
                      (_ctx.localMultimedia==null || _ctx.localMultimedia.length==0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("Multimedia.Add")), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("Multimedia.Edit")), 1))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("a", {
            class: "hyperLink description",
            target: "_blank",
            href: _ctx.$EnvironmentBaseURL +'/StaticContent/MobilizeIT_converting_videos_with_HandbrakeGui.pdf'
          }, _toDisplayString(_ctx.$t("Multimedia.GuideToWorkingWithVideo")), 9, _hoisted_11),
          (_ctx.localMultimediaLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("img", {
                  class: "img-responsive ajax-loader-img",
                  src: _ctx.loadingImage,
                  alt: "Wait"
                }, null, 8, _hoisted_13)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                (_ctx.localMultimedia==null || _ctx.localMultimedia.length==0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      (_ctx.selectedCustomCategoryId==0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t("Multimedia.CreateNewInformationItemMessage")), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t("Multimedia.NoItemMessage")), 1))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localMultimedia, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item.Id,
                          class: "content-under-header"
                        }, [
                          _createVNode(_component_CustomCategoryMultimediaListAndEditItemView, {
                            "selected-edit-multimedia-item": item,
                            "applicationborder-color": _ctx.loaderBorderColor,
                            onCustomCategoryMultimediaItemEditOperation: _cache[1] || (_cache[1] = ($event: any) => (_ctx.CustomCategoryMultimediaItemEditedOperation($event))),
                            onCustomCategoryMultimediaItemSelectOperation: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setCurrentlocalItem($event))),
                            onCustomCategoryMultimediaItemImageUploadOperation: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clickOnImage($event)))
                          }, null, 8, ["selected-edit-multimedia-item", "applicationborder-color"])
                        ]))
                      }), 128))
                    ])),
                _createElementVNode("div", null, [
                  (_ctx.localMultimediaItem)
                    ? (_openBlock(), _createBlock(_component_CustomCategoryMultimediaEditItemPane, {
                        key: 0,
                        "selected-multimedia-item": _ctx.localMultimediaItem,
                        "applicationborder-color": _ctx.loaderBorderColor,
                        onCustomCategoryMultimediaItemCancelOperation: _cache[4] || (_cache[4] = ($event: any) => (_ctx.CustomCategoryMultimediaItemCancelOperation($event))),
                        onCustomCategoryMultimediaItemEditOperation: _cache[5] || (_cache[5] = ($event: any) => (_ctx.CustomCategoryMultimediaItemEditedOperation($event))),
                        onCustomCategoryMultimediaItemImageUploadOperation: _cache[6] || (_cache[6] = ($event: any) => (_ctx.clickOnImage($event)))
                      }, null, 8, ["selected-multimedia-item", "applicationborder-color"]))
                    : _createCommentVNode("", true),
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "addNewMultimediaItem" }, null, -1))
                ])
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}