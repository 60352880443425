import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "dvShareWithevents",
  class: "flex-container scroll-top"
}
const _hoisted_2 = { class: "clear-both" }
const _hoisted_3 = { class: "clear-both" }
const _hoisted_4 = { class: "inline-flex100 align-items-center" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = {
  key: 0,
  class: "margin-top-30 includeVideoInfoMsg"
}
const _hoisted_8 = { class: "padding-top-20" }
const _hoisted_9 = {
  key: 1,
  class: "margin-top-10"
}
const _hoisted_10 = { class: "inline-flex" }
const _hoisted_11 = { class: "padding-3 margin-left-5 description" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "item-position-from-image valign-parent50 width70" }
const _hoisted_14 = { class: "valign" }
const _hoisted_15 = { class: "description-heading" }
const _hoisted_16 = { class: "valign-parent55 width30 text-right" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnBackCustomCategoryEdit()))
            }, _toDisplayString(_ctx.$t("Button.Close")), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass({ 'width55': _ctx.isMobileView ,'widthHeading': !_ctx.isMobileView})
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([{ 'font18': _ctx.isMobileView}, "main-heading primary-heading"])
            }, _toDisplayString(_ctx.$t("Information.ShareWithOtherevents")), 3)
          ], 2),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "btn btn-primary btn-size-default",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SaveShareWithOtherEvents()))
            }, _toDisplayString(_ctx.$t("Button.Save")), 1)
          ])
        ])
      ])
    ]),
    (_ctx.localIsSharedVideoWarningVisibleForShareWithOtherEvent)
      ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
          _cache[4] || (_cache[4] = _createElementVNode("i", {
            class: "fa fa-exclamation-triangle ml-2 mr-4",
            "aria-hidden": "true"
          }, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("ShareWithOtherEventForVodMessage")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.visibleValidationSummary)
        ? (_openBlock(), _createBlock(_component_ValidationSummary, {
            key: 0,
            "error-fields": _ctx.validationErrorFields,
            onCloseClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CloseValidationSummary($event)))
          }, null, 8, ["error-fields"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isBulkOperation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.overwriteLinkedProgram) = $event)),
              type: "checkbox",
              class: "largerCheckbox"
            }, null, 512), [
              [_vModelCheckbox, _ctx.overwriteLinkedProgram]
            ]),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("ProgramAddInformation.OverwritePreviouslyLinkedItems")), 1)
          ]),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("InformationAddProgram.BulkOperationOverwriteDescription")), 1)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localAllEvents, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.Id,
        class: "information-item"
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: _ctx.GetImageUrl(item.ListLogoUrl),
            class: "iconImg"
          }, null, 8, _hoisted_12)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, _toDisplayString(item.Name), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          (item.IsLinked)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-secondary btn-size-default valign",
                onClick: ($event: any) => (_ctx.removeItem(item))
              }, _toDisplayString(_ctx.$t("Button.Remove")), 9, _hoisted_17))
            : _createCommentVNode("", true),
          (!item.IsLinked)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn-primary btn-size-default valign",
                onClick: ($event: any) => (_ctx.addItem(item))
              }, _toDisplayString(_ctx.$t("Button.Add")), 9, _hoisted_18))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}