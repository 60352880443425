import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "right-container-margin" }
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "right-container-nav" }
const _hoisted_4 = { class: "main-title-width" }
const _hoisted_5 = { class: "main-heading primary-heading" }
const _hoisted_6 = { class: "batch-operation" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = { class: "description-text" }
const _hoisted_9 = {
  class: "batch-operation-main-content",
  style: {"display":"flex"}
}
const _hoisted_10 = ["index"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "img-select iconSize" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "selectInformationStyle valign-parent60 item-position-from-image display-flex align-items-start" }
const _hoisted_15 = { class: "valign" }
const _hoisted_16 = ["id"]
const _hoisted_17 = {
  key: 0,
  class: "action-middle-width"
}
const _hoisted_18 = { class: "pro-tip" }
const _hoisted_19 = { class: "proTipTitle" }
const _hoisted_20 = { class: "proTipText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "btn btn-secondary btn-size-default",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Close()))
          }, " Close ")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("CustomCategory.CreateNewTitle")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("CustomCategory.CreateNewItemDescription")), 1)
        ]),
        _createElementVNode("div", {
          id: "pills-tabContent",
          class: _normalizeClass({ '': (_ctx.isiPadView || _ctx.isMobileView),'tab-content': (!_ctx.isiPadView && !_ctx.isMobileView)})
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.CustomCategoryTypes.arrCustomCategoryOptions, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.Id,
                  index: index,
                  class: _normalizeClass(["inner-content", 
                  _ctx.IsEvenIndex(index) == true
                    ? 'action-content-width-left'
                    : 'action-content-width-right'
                ])
                }, [
                  _createElementVNode("div", {
                    class: "inner-content",
                    onClick: ($event: any) => (_ctx.CreateNewCustomCategoryItemTypes(item.Id))
                  }, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("img", {
                        src: item.IconUrl,
                        class: "icon-img"
                      }, null, 8, _hoisted_13)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("label", {
                          id: 'lblCreateNewCustomCategoryItemType'+index,
                          class: "hyperLink questionLinkFont no-margin"
                        }, _toDisplayString(item.DisplayName), 9, _hoisted_16),
                        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(item.Description), 1)
                      ])
                    ])
                  ], 8, _hoisted_11),
                  (_ctx.IsEvenIndex(index) && !_ctx.isMobileView && !_ctx.isiPadView)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17))
                    : _createCommentVNode("", true)
                ], 10, _hoisted_10))
              }), 128))
            ])
          ])
        ], 2),
        _createElementVNode("div", _hoisted_18, [
          _cache[2] || (_cache[2] = _createElementVNode("div", null, [
            _createElementVNode("i", { class: "fa fa-lightbulb-o proTipIcon pro-tip-icon" })
          ], -1)),
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("CustomCategory.Protip")), 1),
            _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t("CustomCategory.ProtipDescription")), 1)
          ])
        ])
      ])
    ])
  ]))
}