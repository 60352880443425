import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-container toggleswitch background-white border-radius-20" }
const _hoisted_2 = { class: "new-nav-bar-container inline-flex background-white" }
const _hoisted_3 = { class: "font-size-20 no-margin" }
const _hoisted_4 = {
  key: 0,
  class: "bttons"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  id: "customCategoryItems",
  class: "tab-pane tab-flex-container"
}
const _hoisted_7 = { class: "toolbarContainer" }
const _hoisted_8 = { class: "new-left-side-tool-bar" }
const _hoisted_9 = { class: "no-item margin-left-10" }
const _hoisted_10 = {
  id: "spnSelectAllCustomCategoryItems",
  class: "select-all-position bold-text"
}
const _hoisted_11 = { class: "right-side-tool-bar" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "margin-left-5" }
const _hoisted_14 = { class: "inner-flex-container" }
const _hoisted_15 = { class: "left-inner-flex-container" }
const _hoisted_16 = {
  key: 0,
  id: "right-inner-flex-container",
  class: "edit-panel right-inner-flex-container"
}
const _hoisted_17 = {
  key: 1,
  class: "edit-panel right-inner-flex-container"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "area100" }
const _hoisted_20 = ["src"]
const _hoisted_21 = {
  key: 1,
  class: "bttons create-new-btn-div"
}
const _hoisted_22 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_CustomCategoryListView = _resolveComponent("CustomCategoryListView")!
  const _component_CustomCategoryParentEditPane = _resolveComponent("CustomCategoryParentEditPane")!
  const _component_CustomCategoryAddItem = _resolveComponent("CustomCategoryAddItem")!
  const _component_CustomCategoryBatchEditPane = _resolveComponent("CustomCategoryBatchEditPane")!
  const _component_CustomCategoryFolderListView = _resolveComponent("CustomCategoryFolderListView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.modalPopupHeading,
          "body-heading": _ctx.modalPopupContentHeading,
          "body-content": _ctx.modalPopupContent,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Information')), 1),
      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "width30 no-mobile-item-display" }, [
        _createElementVNode("div", { class: "toggling-container margin-top-10" })
      ], -1)),
      (!_ctx.isMobileView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("button", {
              id: "btnCreateNewCustomCategoryItem",
              class: "btn btn-size-header-main btn-primary",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewCustomCategoryItemClick && _ctx.btnCreateNewCustomCategoryItemClick(...args)))
            }, [
              _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_5),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("CreateNew" )), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              id: "inputSelectAllCustomCategoryItems",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectAllCustomCategoryItems) = $event)),
              type: "checkbox"
            }, null, 512), [
              [_vModelCheckbox, _ctx.selectAllCustomCategoryItems]
            ]),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("SelectAll" )), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", {
            id: "btnCreateNewCustomCategoryFolder",
            class: "menu-item",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewCustomCategoryFolderClick && _ctx.btnCreateNewCustomCategoryFolderClick(...args)))
          }, [
            _createElementVNode("img", {
              src: _ctx.manageIcon,
              class: "menu-item-icon"
            }, null, 8, _hoisted_12),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("Information.ManageFolders")), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          (_ctx.viewModel)
            ? (_openBlock(), _createBlock(_component_CustomCategoryListView, {
                key: 0,
                "custom-category-items": _ctx.viewModel.CustomCategoryItems,
                "select-all-items": _ctx.selectAllCustomCategoryItems,
                "editing-custom-category-item": _ctx.localEditedCustomCategoryItem,
                "applicationborder-color": _ctx.loaderBorderColor,
                folders: _ctx.viewModel.Folders,
                "create-new-unsaved-flag": _ctx.createNewUnsavedFlag,
                onCustomCategoryItemsSelected: _cache[4] || (_cache[4] = ($event: any) => (_ctx.CustomCategoryItemsSelected($event))),
                onChangeCustomCategoryItem: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ChangeCustomCategoryItem($event))),
                onUpdateSortingCustomCategoryListing: _cache[6] || (_cache[6] = ($event: any) => (_ctx.UpdateSortingCustomCategoryListing($event))),
                onCustomCategoryItemCancelOperation: _cache[7] || (_cache[7] = ($event: any) => (_ctx.customCategoryItemCancelOperation())),
                onCreateNewCustomCategoryClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.CreateCustomCategory()))
              }, null, 8, ["custom-category-items", "select-all-items", "editing-custom-category-item", "applicationborder-color", "folders", "create-new-unsaved-flag"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.showCustomCategoryParentEditPane)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("div", null, [
                _createVNode(_component_CustomCategoryParentEditPane, {
                  ref: "parent",
                  "selected-custom-category-item": Object.assign({}, _ctx.selectedCustomCategoryItems[0]),
                  "custom-category-type-id": _ctx.customCategoryTypeId,
                  "available-custom-category-folders": _ctx.viewModel.CustomCategoryFolders,
                  "participant-groups": _ctx.participantsGroups,
                  "available-events": _ctx.viewModel.EventItems,
                  onCustomCategoryFolderCloseOperation: _cache[9] || (_cache[9] = ($event: any) => (_ctx.CustomCategoryFolderItemCancelOperation()))
                }, null, 8, ["selected-custom-category-item", "custom-category-type-id", "available-custom-category-folders", "participant-groups", "available-events"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.showCustomCategoryAddItem)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createElementVNode("div", null, [
                _createVNode(_component_CustomCategoryAddItem, {
                  ref: "parent",
                  onCustomCategoryFolderCloseOperation: _cache[10] || (_cache[10] = ($event: any) => (_ctx.CustomCategoryFolderItemCancelOperation()))
                }, null, 512)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent != _ctx.SelectedBatchCustomCategory}])
        }, [
          (_ctx.selectedCustomCategoryItems.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createVNode(_component_CustomCategoryBatchEditPane, {
                  "selected-items": _ctx.selectedCustomCategoryItems,
                  "folder-items": _ctx.viewModel.CustomCategoryFolders,
                  "available-program-items": _ctx.viewModel.ProgramItems
                }, null, 8, ["selected-items", "folder-items", "available-program-items"])
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          id: "customCategoryFolders",
          class: _normalizeClass(["edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent != _ctx.folders}])
        }, [
          (_ctx.activeSubComponent==_ctx.folders)
            ? (_openBlock(), _createBlock(_component_CustomCategoryFolderListView, {
                key: 0,
                "folder-items": _ctx.viewModel.CustomCategoryFolders,
                "applicationborder-color": _ctx.loaderBorderColor,
                "font-awesome-icons": _ctx.viewModel.FontAwesomeIcons,
                onCustomCategoryFolderCloseOperation: _cache[11] || (_cache[11] = ($event: any) => (_ctx.CustomCategoryFolderItemCancelOperation()))
              }, null, 8, ["folder-items", "applicationborder-color", "font-awesome-icons"]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel no-item right-inner-flex-container", { hidden: _ctx.activeSubComponent != ''}])
        }, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("iframe", {
              class: "iframe-area",
              src: _ctx.GetIframeUrl()
            }, null, 8, _hoisted_20)
          ])
        ], 2)
      ])
    ]),
    (_ctx.isMobileView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createElementVNode("button", {
            id: "btnCreateNewCustomCategoryItem",
            class: "btn btn-size-header-main btn-primary create-new-btn",
            onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewCustomCategoryItemClick && _ctx.btnCreateNewCustomCategoryItemClick(...args)))
          }, [
            _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_22),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("CreateNew" )), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}