import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vShow as _vShow, vModelCheckbox as _vModelCheckbox, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "item-listing" }
const _hoisted_2 = {
  key: 1,
  class: "emptyDiv"
}
const _hoisted_3 = { class: "no-item-parent-div" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "no-item-message-container" }
const _hoisted_6 = { class: "no-item-message" }
const _hoisted_7 = {
  key: 2,
  class: "itemsDiv"
}
const _hoisted_8 = { class: "toggling-container" }
const _hoisted_9 = { class: "toggling-left-container" }
const _hoisted_10 = { class: "folder-label-container" }
const _hoisted_11 = { class: "information-folder-label" }
const _hoisted_12 = { class: "large-display" }
const _hoisted_13 = { class: "small-display" }
const _hoisted_14 = { class: "folder-container" }
const _hoisted_15 = { value: "All" }
const _hoisted_16 = { value: "" }
const _hoisted_17 = ["value"]
const _hoisted_18 = { class: "toggling-right-container" }
const _hoisted_19 = { class: "margin-top-adjust" }
const _hoisted_20 = { class: "" }
const _hoisted_21 = {
  id: "spnSortCustomCategoryItemsAscendingOrder",
  class: "large-display"
}
const _hoisted_22 = {
  id: "spnSortCustomCategoryItemsDescendingOrder",
  class: "large-display"
}
const _hoisted_23 = {
  key: 0,
  class: "emptyFolderDiv"
}
const _hoisted_24 = { class: "no-item-parent-div" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = { class: "no-item-message-container" }
const _hoisted_27 = { class: "no-item-message" }
const _hoisted_28 = ["index"]
const _hoisted_29 = ["onMouseover"]
const _hoisted_30 = { class: "main-body-container inline-flex100" }
const _hoisted_31 = ["src"]
const _hoisted_32 = {
  key: 0,
  class: "checkbox-container"
}
const _hoisted_33 = ["id", "value", "onClick"]
const _hoisted_34 = ["onClick"]
const _hoisted_35 = ["src"]
const _hoisted_36 = { class: "valign" }
const _hoisted_37 = { class: "information-type-and-folder" }
const _hoisted_38 = { class: "margin-right-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_loading = _resolveComponent("loading")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.modalPopupHeading,
          "body-heading": _ctx.modalPopupContentHeading,
          "body-content": _ctx.modalPopupContent,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
      : _createCommentVNode("", true),
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.customCategoryItems == null || _ctx.customCategoryItems.length == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", null, [
              _createElementVNode("label", {
                class: "no-item-font-weight font-size-30",
                innerHTML: _ctx.$t('CustomCategory.LandingPageNoItemMessage')
              }, null, 8, _hoisted_4)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.$t("StartBytext")) + " ", 1),
              _createElementVNode("a", {
                class: "hyperLink questionLinkFont",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.OpenCreateNewQuestionpane()))
              }, _toDisplayString(_ctx.$t("CustomCategory.CreatingQuestionLink")), 1)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("Information.FolderName")) + " :", 1),
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("Information.Folder")) + " :", 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _withDirectives(_createElementVNode("select", {
                  id: "ddlstFolders",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedFolderName) = $event)),
                  class: "form-control selectBox",
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.OnFolderChange($event.target.value)))
                }, [
                  _createElementVNode("option", _hoisted_15, _toDisplayString(_ctx.$t("ProgramAddInformation.All")), 1),
                  _createElementVNode("option", _hoisted_16, _toDisplayString(_ctx.$t("ProgramAddInformation.TopLevel")), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customCategoryFolders, (folder) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: folder,
                      value: folder
                    }, _toDisplayString(folder), 9, _hoisted_17))
                  }), 128))
                ], 544), [
                  [_vModelSelect, _ctx.selectedFolderName]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _withDirectives(_createElementVNode("div", _hoisted_19, [
                _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("SortItemByName")) + " :", 1),
                _createElementVNode("span", {
                  class: _normalizeClass(["hyperLink margin-left-5", {'padding-left-5 padding-right-5': _ctx.isMobileView}]),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.SortCustomCategoryListing()))
                }, [
                  _cache[11] || (_cache[11] = _createElementVNode("i", {
                    class: "fa fa-sort-alpha-asc small-display",
                    "aria-hidden": "true"
                  }, null, -1)),
                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("Ascending")), 1)
                ], 2),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("Or")) + " ", 1),
                _createElementVNode("span", {
                  class: "hyperLink margin-left-5",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.SortCustomCategoryListingDesc()))
                }, [
                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t("Descending")), 1),
                  _cache[12] || (_cache[12] = _createElementVNode("i", {
                    class: "fa fa-sort-alpha-desc small-display",
                    "aria-hidden": "true"
                  }, null, -1))
                ])
              ], 512), [
                [_vShow, 
              _ctx.filteredCustomCategoryItems != null &&
                _ctx.filteredCustomCategoryItems.length > 0
            ]
              ])
            ])
          ]),
          (
          _ctx.filteredCustomCategoryItems == null ||
            _ctx.filteredCustomCategoryItems.length == 0
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("h2", null, [
                    _createElementVNode("label", {
                      class: "no-item-font-weight font-size-30",
                      innerHTML: _ctx.$t('CustomCategory.LandingPageNoItemInFolderMessage')
                    }, null, 8, _hoisted_25)
                  ])
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("span", _hoisted_27, [
                    _createTextVNode(_toDisplayString(_ctx.$t("AddItemBy")) + " ", 1),
                    _createElementVNode("a", {
                      class: "hyperLink questionLinkFont",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.OpenCreateNewQuestionpane()))
                    }, _toDisplayString(_ctx.$t("CustomCategory.CreatingQuestionLink")), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createVNode(_component_draggable, {
              list: _ctx.filteredCustomCategoryItems,
              tag: "ul",
              class: "list-group",
              handle: ".handle",
              animation: "500",
              onStart: _cache[9] || (_cache[9] = ($event: any) => (_ctx.draggingStart($event, { passive: false }))),
              onEnd: _cache[10] || (_cache[10] = ($event: any) => (_ctx.draggingEnd($event, { passive: false })))
            }, {
              default: _withCtx(() => [
                _createVNode(_TransitionGroup, { type: "transition" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCustomCategoryItems, (customCategoryItem, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: customCategoryItem.CustomCategoryId,
                        index: index,
                        class: "item-default-bg-color",
                        style: _normalizeStyle([
                customCategoryItem.DisableItem == true
                  ? { 'pointer-events': 'none', opacity: '0.4' }
                  : '',
              ])
                      }, [
                        _createElementVNode("div", {
                          class: "main-item-div cusror-pointer",
                          style: _normalizeStyle({
                  'background-color': customCategoryItem.styleDefaultDiv,
                }),
                          onMouseover: ($event: any) => (_ctx.ShowDragAndDropIcon(index)),
                          onMouseleave: _cache[8] || (_cache[8] = ($event: any) => (_ctx.ShowDragAndDropIcon(null)))
                        }, [
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["valign-parent50 drag-drop-icon cursor-move handle", {'visibility-hidden': _ctx.hoverItemIndex !== index && !_ctx.isTabView}])
                            }, [
                              _createElementVNode("img", { src: _ctx.dragDropIcon }, null, 8, _hoisted_31)
                            ], 2),
                            (_ctx.isMobileView == false)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                  _withDirectives(_createElementVNode("input", {
                                    id: 'pi_' + customCategoryItem.CustomCategoryId,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedItems) = $event)),
                                    type: "checkbox",
                                    class: "noItemDisplay",
                                    value: customCategoryItem,
                                    onClick: ($event: any) => (
                        _ctx.CheckCustomCategoryItems(
                          customCategoryItem,
                          index,
                          $event
                        )
                      )
                                  }, null, 8, _hoisted_33), [
                                    [_vModelCheckbox, _ctx.selectedItems]
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("a", {
                              href: "javascript:void(0)",
                              class: "inline-flex100 ipad_tag",
                              onClick: ($event: any) => (_ctx.SelectItem(customCategoryItem))
                            }, [
                              _createElementVNode("div", {
                                class: _normalizeClass(["img-select", {'full-image-icon-div': customCategoryItem.IsFullImage}])
                              }, [
                                _createElementVNode("img", {
                                  src: customCategoryItem.IconUrl,
                                  class: _normalizeClass([customCategoryItem.IsFullImage ? 'full-image-icon': 'image-icon', {'round-image': !customCategoryItem.IsFullImage}])
                                }, null, 10, _hoisted_35)
                              ], 2),
                              _createElementVNode("div", {
                                class: _normalizeClass([{ width65: _ctx.isMobileView, width80: !_ctx.isMobileView }, "margin-left-15 valign-parent50"])
                              }, [
                                _createElementVNode("div", _hoisted_36, [
                                  _createElementVNode("p", {
                                    class: "time description-heading",
                                    style: _normalizeStyle({'color': customCategoryItem.styleDefaultHeadingText})
                                  }, _toDisplayString(customCategoryItem.Title), 5),
                                  _createElementVNode("div", _hoisted_37, [
                                    _createElementVNode("div", _hoisted_38, [
                                      _createElementVNode("i", {
                                        style: _normalizeStyle({'color': customCategoryItem.styleDefaultHeadingText}),
                                        class: "fa fa-th-large"
                                      }, null, 4),
                                      _createElementVNode("span", {
                                        style: _normalizeStyle({'color': customCategoryItem.styleDefaultSubHeadingText}),
                                        class: "margin-left4"
                                      }, _toDisplayString(_ctx.SelectedTypeFormat(customCategoryItem.ContentTypeV2)), 5)
                                    ]),
                                    _createElementVNode("div", null, [
                                      _createElementVNode("i", {
                                        style: _normalizeStyle({'color': customCategoryItem.styleDefaultHeadingText}),
                                        class: "fa fa-folder-open"
                                      }, null, 4),
                                      (customCategoryItem.CategoryFolder)
                                        ? (_openBlock(), _createElementBlock("span", {
                                            key: 0,
                                            style: _normalizeStyle({'color': customCategoryItem.styleDefaultSubHeadingText}),
                                            class: "margin-left4"
                                          }, _toDisplayString(customCategoryItem.CategoryFolder.Name), 5))
                                        : (_openBlock(), _createElementBlock("span", {
                                            key: 1,
                                            style: _normalizeStyle({'color': customCategoryItem.styleDefaultSubHeadingText}),
                                            class: "margin-left4"
                                          }, _toDisplayString(_ctx.$t("ProgramAddInformation.TopLevel")), 5))
                                    ])
                                  ])
                                ])
                              ], 2)
                            ], 8, _hoisted_34)
                          ])
                        ], 44, _hoisted_29)
                      ], 12, _hoisted_28))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["list"])
          ])
        ]))
  ]))
}